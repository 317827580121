// Documenttype.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { Documenttype } from '../model/documenttype';


@Injectable({
    providedIn: 'root',
})
export class DocumentTypeService {
    apiUrl: any;
    BearerToken: any;
    categories: Documenttype[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAlldocument(): Promise<Documenttype[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<Documenttype[]>("Documenttype");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching categories:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    getDocumenttypeById(id: number): Observable<Documenttype> {
        return this.http.get<Documenttype>(`${this.apiUrl}/api/Documenttype/${id}`);
    }

    async addDocumenttype(Documenttype: Documenttype) {
        Documenttype.DocumentTypeId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Documenttype", Documenttype);
        return res;
    }

    async updateDocumenttype(Documenttype: Documenttype): Promise<Observable<Documenttype>> {
        let res = await this.service.Data.ExecuteAPI_Put("Documenttype", `${Documenttype.DocumentTypeId}?DocumentTypeId=${Documenttype.DocumentTypeId}`, Documenttype);
        return res;
    }

    async deleteDocumenttype(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("Documenttype", `${id}?DocumentTypeId=${id}`);
        return res;
    }
}
