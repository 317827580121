<app-navbar-style-admin></app-navbar-style-admin>
<!-- your-component.component.html -->
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Update Questions</h4>
                        </header>
                        <article class="card-body">
                            <div class="container">
                                <form [formGroup]="QuestionsForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <label>Select Questionnaire:</label>
                                        <ng-select formControlName="QuestionnaireId" #myselect
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="QuestionnaireId"
                                            class="form-control">
                                            <ng-option *ngFor="let obj of Questionnaires" [value]="obj.id"
                                                class="form-control">{{obj.name}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="QuestionsForm.get('QuestionnaireId')?.hasError('required') && QuestionsForm.get('QuestionnaireId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="QuestionType">QuestionType:</label>
                                        <ng-select class="form-control" formControlName="QuestionType"
                                            [(ngModel)]="QuestionType" required>
                                            <ng-option *ngFor="let type of questionTypes" [value]="type"
                                                class="form-control">{{ type }}</ng-option>
                                        </ng-select>
                                        <div *ngIf="QuestionsForm.get('QuestionType')?.hasError('required') && QuestionsForm.get('QuestionType')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Identifier">Identifier:</label>
                                        <input type="text" class="form-control" formControlName="Identifier" required>
                                        <div *ngIf="QuestionsForm.get('Identifier')?.hasError('required') && QuestionsForm.get('Identifier')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Headline">Headline:</label>
                                        <input type="text" class="form-control" formControlName="Headline" required>
                                        <div *ngIf="QuestionsForm.get('Headline')?.hasError('required') && QuestionsForm.get('Headline')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>


                                    <div class="form-group">

                                        <label for="Description">Description:</label>
                                        <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                                            formControlName="Description" (onEditorChanged)="changedEditor($event)"
                                            (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                                        <div *ngIf="QuestionsForm.get('Description')?.hasError('required') && QuestionsForm.get('Description')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <label for="Required">Required:</label>
                                        <input type="checkbox" formControlName="Required">
                                        <div *ngIf="QuestionsForm.get('Headline')?.hasError('required') && QuestionsForm.get('Headline')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Multiple">Multiple:</label>
                                        <input type="checkbox" formControlName="Multiple">
                                        <div *ngIf="QuestionsForm.get('Multiple')?.hasError('required') && QuestionsForm.get('Multiple')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Multiline">Multiline:</label>
                                        <input type="checkbox" formControlName="Multiline">
                                        <div *ngIf="QuestionsForm.get('Multiline')?.hasError('required') && QuestionsForm.get('Multiline')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div>
                                </form>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-footer-style-two></app-footer-style-two>
