
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { filterHeaderValueModel } from '../../model/filterHeaderValue';
import { FilterHeaderValueService } from '../../services/filterHeaderValue.service';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-filterHeader_listValue-form',
    templateUrl: './filterHeader_listValue.html',
    styleUrls: ['./filterHeader_listValue.css'],
})
export class FilterHeaderListValueformComponent implements OnInit {

    filterHeader: filterHeaderValueModel;
    isLoading = false;
    allfilterheader: any;


    constructor(private router: Router,
        public service: SystemService, public filterHeaderservices: FilterHeaderValueService, private alertify: AlertifyService) { }


    ngOnInit() {
        this.loadfilterHeader();
        this.refreshGridData();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'filterHeaderValueId', sortable: true, filter: true, width: 20 },
        { headerName: 'FilterName', field: 'firstName', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'FilterValue', field: 'filterValue', sortable: true, filter: true, editable: true, width: 200 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    refreshGridData(): void {
        this.loadfilterHeader();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
     }
    actionsCellRenderer(params: any) {
        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        wrapper.appendChild(deleteButton);
        return wrapper;
    }

    addRow() {
        this.router.navigate(['/filterHeaderaddValue']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.filterHeaderValueId;
        this.router.navigate(['/filterHeadereditValue', id]);

    }
    deleteRow(rowData: any) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.filterHeaderservices.deleteFilterHeaderValue(rowData.filterHeaderValueId);
            console.log(JSON.stringify(res));
            this.loadfilterHeader();
            this.refreshGridData();
            this.alertify.success("Deleted Successfully");
        } else {
            console.log('Deletion canceled.');
        }
    }

    loadfilterHeader() {
        this.service.App.ShowLoader = true;
        this.filterHeaderservices.getAllFilterHeaderValue().then(allfilterheader => {
            this.allfilterheader = allfilterheader;
            this.rowData = allfilterheader;
            console.log(JSON.stringify(this.allfilterheader));
        });
        this.service.App.ShowLoader = false;

    }

    addFilterHeader() {
        this.router.navigate(['/filterHeaderaddValue']);
    }


}


export { filterHeaderValueModel };

