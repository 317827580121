// category.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '../model/category';
import { SystemService } from './system.service';


@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    apiUrl: any;
    BearerToken: any;
    categories: Category[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllCategories(): Promise<Category[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<Category[]>("Category");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching categories:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

    getCategoryById(id: number): Observable<Category> {
        return this.http.get<Category>(`${this.apiUrl}/api/Category/${id}`);
    }

    async addCategory(category: Category) {
        category.categoryId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Category", category);
        return res;
    }

    async updateCategory(category: Category): Promise<Observable<Category>> {
        let res = await this.service.Data.ExecuteAPI_Put("Category",`${category.categoryId}?categoryid=${category.categoryId}`, category);
        return res;
    }

    async deleteCategory(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("Category", `${id}?categoryid=${id}`);
         return res;
    }
}
