import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Gallery } from '../../model/gallery';
import { GalleryService } from '../../services/gallery.service';
import { Documenttype } from '../documenttype/documenttype';

@Component({
    selector: 'app-gallery-list',
    templateUrl: './gallery-list.component.html',
    styleUrls: ['./gallery-list.component.scss'],
    providers: [DatePipe],
})
export class GalleryListComponent implements OnInit {
    galleryform: FormGroup;
    gallery: Gallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    galleryItems: any;
    isLoading = false;
    @Input() documenttype: Documenttype[] = [];
    @Output() editDocumenttype = new EventEmitter<Documenttype>();
    @Output() deleteDocumenttype = new EventEmitter<number>();
    apiPath: any;
    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private galleryService: GalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService) { }

    ngOnInit() {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadGallery();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'galleryId', sortable: true, filter: true, width: 20 },
        { headerName: 'Type', field: 'typeTitle', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'Name', field: 'documentName', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Photo', field: 'galleryPhoto', cellRenderer: this.imageCellRenderer.bind(this), editable: false, width: 120, cellClass: 'zoomable-photo-cell' },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;
    refreshGridData(): void {
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
     }
    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    imageCellRenderer(params: any) {
        return `<img src="${this.apiPath + '/Documents/appImages/' + params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    actionsCellRenderer(params: any) {
        // const addButton = document.createElement('button');
        // addButton.innerHTML = 'Add';
        // addButton.classList.add('Actions-btn'); // Apply the CSS class
        // addButton.addEventListener('click', () => this.addRow());

        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        //wrapper.appendChild(addButton);
        wrapper.appendChild(deleteButton);

        return wrapper;
    }

    addRow() {
        this.router.navigate(['/addgallery']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.galleryId;
        this.router.navigate(['/editgallery', id]);

    }
    deleteRow(rowData: any) {

        console.log(rowData);
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.galleryService.deleteGallery(rowData.galleryId);
            console.log(JSON.stringify(res));
            this.loadGallery();
            this.refreshGridData();
            this.alertify.success("Deleted Successfully");
        } else {
            console.log('Deletion canceled.');
        }

    }

    loadGallery() {
        this.service.App.ShowLoader = true;
        this.galleryService.getAllGallerys().then(gallerylist => {
            this.galleryItems = gallerylist;
            this.rowData = gallerylist;
            console.log(JSON.stringify(this.galleryItems));
        });
        this.service.App.ShowLoader = false;

    }

    addGallery() {
        this.router.navigate(['/addgallery']);
    }


}

