import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Blog } from '../model/blog';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class NewBlogService {
    private apiPath: string;
constructor(private http:HttpClient,public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }

// addBlog(blog: Blog){
//   let newBlog = [];
//   if(localStorage.getItem('Blogs')) {
//     newBlog = JSON.parse(localStorage.getItem('Blogs'));
//     newBlog = [blog, ...newBlog];
//   } else {
//     newBlog = [blog];
//   }
//   localStorage.setItem('Blogs', JSON.stringify(newBlog));
// }

addBlog(blog: Blog) {
  return this.http.post(this.apiPath + '/api/Post/post', blog);
}

newpostID() {
  if(localStorage.getItem('PID')) {
    localStorage.setItem('PID', String(+localStorage.getItem('PID')+1));
    return +localStorage.getItem('PID');
  } else {
    localStorage.setItem('PID', '101');
    return 101;
  }
}
}
