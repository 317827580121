<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;" >
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Document</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="documenttypeForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label for="DocumentName">Document Name:</label>
                                    <input type="text" id="DocumentName" class="form-control"
                                        formControlName="DocumentName">
                                        <div *ngIf="documenttypeForm.get('DocumentName').hasError('required')">
                                            Field is required.
                                        </div>
                                </div>
                                <div class="form-group">
                                    <label for="DocumentDescp">Document Description:</label>
                                    <input type="text" id="DocumentDescp" class="form-control"
                                        formControlName="DocumentDescp">
                                        <div *ngIf="documenttypeForm.get('DocumentDescp').hasError('required')">
                                            Field is required.
                                        </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block" [disabled]="!documenttypeForm.valid">Save</button>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <!-- <header class="card-header" style="background: var(--mainColor);">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create document</h4>
                        </header> -->
                        <article class="card-body">
                            <!-- Grid section -->
                            <app-Documenttype-grid [documenttype]="documenttype" (editdocument)="onEdit($event)"
                                (deletedocument)="onDelete($event)"></app-Documenttype-grid>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
