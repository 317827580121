

<app-navbar-style-admin></app-navbar-style-admin>
<div class="inner-banner inner-bg4">
    <div class="container">
        <!-- <div class="inner-banner-title text-center">
            <h3>{{post.title}}</h3>
           <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p>
        </div> -->

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/adminPanel">Dashboard</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Questions Details</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><button (click)="addRow()" class="default-btn">Add Questions</button></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create your Questions</h4>
                        </header>
                        <article class="card-body">
                            <div *ngIf="isLoading" class="overlay-inner">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <div class="custom-ag-grid-container ag-theme-alpine">
                                <ag-grid-angular
                                [rowData]="rowData"
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef"
                                [pagination]="true"
                                [domLayout]="'autoHeight'"
                                (gridReady)="onGridReady($event)"
                                (selectionChanged)="onSelectionChanged($event)"
                              ></ag-grid-angular>
                              </div>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
