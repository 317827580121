import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../model/user';
import { UserServiceService } from '../../services/user-service.service';
import { HttpClient } from '@angular/common/http';
import { EncryptionService } from '../../services/encryption.service';
import { AuthRegister } from '../../model/authregister';
import { AuthLogin } from '../../model/authlogin';
import { SystemService } from '../../services/system.service';
import { KeyValueString } from '../../model/common_model';


@Component({
    selector: 'app-login-register',
    templateUrl: './login-register.component.html',
    styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {
    sessionExpirationSeconds: number = 60 * 60;
    isAdmin: boolean = false;
    LanguageList: Array<KeyValueString> = []; SelectedLang: KeyValueString;
    registrationForm: FormGroup; forgetPasswordForm: FormGroup; loginForm: FormGroup;
    authRegister: AuthRegister;
    returnUrl: string;
    userSubmitted: boolean;
    userNameTaken: boolean;
    AuthLogin: { email: any; password: any; };
    showPassword: boolean = false; isPasswordPresent: boolean = false;
    showPassword2: boolean = false; isPasswordPresent2: boolean = false;
    showPassword3: boolean = false; isPasswordPresent3: boolean = false;
    constructor(private authService: AuthService, public route: ActivatedRoute, private fb: FormBuilder, private userService: UserServiceService,
        private alertify: AlertifyService, private encryptionService: EncryptionService, private http: HttpClient, public service: SystemService,
        private router: Router) {
        this.initForm();
        //!IMP
        this.showPassword = false; this.isPasswordPresent = false;
        this.showPassword2 = false; this.isPasswordPresent2 = false;
        //this.bindLanguages();
        this.service.HasAccountData.then(() => {
            if (this.service.Account.UserID > 0) {
                this.router.navigate(['/adminPanel']);//redirect to dashboard page
            }
        });
    }
    async initForm() {
        this.createLoginFormform();
        this.createRegistrationform();
        this.createForgetPasswordform();
    }
    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    }
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        console.log(this.showPassword);
    }

    onPasswordChange() {
        const passwordValue = this.registrationForm.get('password').value;
        this.isPasswordPresent = !!passwordValue;
        console.log(this.isPasswordPresent);
    }

    togglePasswordVisibility2() {
        this.showPassword2 = !this.showPassword2;
        console.log(this.showPassword2);
    }

    onPasswordChange2() {
        const passwordValue = this.registrationForm.get('ConfirmPassword').value;
        this.isPasswordPresent2 = !!passwordValue;
        console.log(this.isPasswordPresent2);
    }

    togglePasswordVisibility3() {
        this.showPassword3 = !this.showPassword3;
        console.log(this.showPassword3);
    }

    onPasswordChange3() {
        const passwordValue = this.loginForm.get('password').value;
        this.isPasswordPresent3 = !!passwordValue;
        console.log(this.isPasswordPresent3);
    }
    //!IMP
    // async bindLanguages() {
    //     this.LanguageList = await this.service.Get_Languages();
    //     let lang_storage = localStorage.getItem("lang");
    //     if (lang_storage) {
    //         this.SelectedLang = this.LanguageList.find(d => d.Value == lang_storage)!;
    //     }
    // }

    // async GetDefaultLanguage() {
    //     try {
    //         let res = await this.service.Data.ExecuteAPI_Post<string>("Home/Get_DefaultLang");
    //         if (res) {
    //             let lang = res.data;
    //             let lang_storage = localStorage.getItem("lang") || '{}';

    //             if (lang_storage) {
    //                 lang = lang_storage;

    //                 this.SelectedLang = this.LanguageList.find(d => d.Value == lang)!;
    //                 this.service.CL = this.SelectedLang.Value;
    //                 this.service.Translator.use(this.SelectedLang.Value);
    //                 localStorage.setItem("lang", this.SelectedLang.Value);
    //                 this.LoginForm.controls["language"].setValue(this.SelectedLang.Value);
    //             }
    //             else {
    //                 let item = this.LanguageList.find(d => d.Value == lang);
    //                 this.ChangeLang(item);
    //             }
    //         }
    //     } catch (e) { }
    // }

    async onLogin() {
        try {
            if (this.loginForm.valid) {

                let obj = this.loginForm.getRawValue();
                this.AuthLogin = {
                    email: obj.email,
                    password: obj.password
                }
                console.log(JSON.stringify(this.AuthLogin));
                let value = this.authService.validateUser(this.AuthLogin)
                if (await value == 1) {
                    this.loginForm.reset();
                    this.alertify.success("Login Successfull");
                    await this.service.loadAccountDetail();
                    if (this.returnUrl && this.returnUrl != '' && this.returnUrl != '/') {
                        this.router.navigate([this.returnUrl]);
                    }
                    else { this.router.navigate(['/adminPanel']); }

                    //this.service.Data.ExecuteAPI_Post<any>("Admin/Set_Current_Languages", { lang: this.SelectedLang.Value }).then((res) => { });
                }
                else {
                    this.alertify.error("Login unsuccessfull");
                }
            }
            else {
                this.alertify.error("Please provide username and password!");
            }

        } catch (e) {
            // this.isLoading = false;
            this.alertify.error("Please check the Network!");
        }
    }

    createForgetPasswordform() {
        this.forgetPasswordForm = this.fb.group(
            {
                email: [null, [Validators.required, Validators.email]],
            }
        );
    }

    createLoginFormform() {
        this.loginForm = this.fb.group(
            {
                email: [null, [Validators.required, Validators.email]],
                password: [null, [Validators.required, Validators.minLength(8)]],
            },
        );
    }

    createRegistrationform() {

        const passwordMatchValidator = (control: FormGroup) => {
            const password = control.get('password')?.value;
            const confirmPassword = control.get('ConfirmPassword')?.value;

            // Check if passwords match
            return password === confirmPassword ? null : { passwordsNotMatch: true };
        };

        this.registrationForm = this.fb.group(
            {
                userName: [null, Validators.required],
                email: [null, [Validators.required, Validators.email]],
                password: [null, [Validators.required, Validators.minLength(8)]],
                ConfirmPassword: [null, Validators.required]
            }, { validators: passwordMatchValidator }
        );
    }

    // passwordMatchingValidator(registrationForm: FormGroup): Validators {
    //     return registrationForm.get('password').value === registrationForm.get('confirmPassword').value ? null :
    //         { notmatched: true }
    // }

    // get userName() {
    //     return this.registrationForm.get('userName') as FormControl;
    // }

    // get email() {
    //     return this.registrationForm.get('email') as FormControl;
    // }

    // get password() {
    //     return this.registrationForm.get('password') as FormControl;
    // }

    // get ConfirmPassword() {
    //     return this.registrationForm.get('ConfirmPassword') as FormControl;
    // }
    //Forgetpassword
    async onSubmitforgetPassword() {
        if (this.forgetPasswordForm.valid) {
            console.log(this.registrationForm);
            let obj = this.forgetPasswordForm.getRawValue();
            let value = this.authService.forgetpassword(obj.email);
            if (value) {
                this.forgetPasswordForm.reset();
                this.userSubmitted = false;
                this.alertify.success("We have sent registered email successfully");
                //this.router.navigate(['/login']);
                //this.user = Object.assign(this.user, this.registrationForm.value);
                //this.userService.addUser(this.userData());
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        } else {
            this.alertify.error("Kindly provide the required fields");
        }


    }
    //Registration
    onSubmit() {
        this.userSubmitted = true;
        const loginTab = document.getElementById('loginTab');
        const registerTab = document.getElementById('registerTab');
        if (this.registrationForm.valid) {
            console.log(this.registrationForm);
            this.userService.addUser(this.userData()).subscribe(
                (data: UserResponse) => {
                    this.registrationForm.reset();
                    this.userSubmitted = false;
                    // Check if the operation succeeded
                    if (data.succeeded) {
                        this.alertify.success("You have successfully registered, login with your credentials");
                    } else {
                        console.log("Operation failed. Please try again.");
                        // Check if there are errors
                        if (data.errors.length > 0) {
                            this.alertify.success("Operation failed. Please try again.");
                            // Loop through errors and log code and description
                            data.errors.forEach(error => {
                                this.alertify.warning("Error Code:  " + error.code);
                                this.alertify.warning("Error Description:  "  + error.description);
                            });
                        } else {
                            this.alertify.warning("Operation was successful!");
                        }
                    }
                    if (loginTab) {
                        loginTab.click();
                    }
                    //this.router.navigate(['/login']);
                    //this.user = Object.assign(this.user, this.registrationForm.value);
                    //this.userService.addUser(this.userData());
                }
            );
        } else {
            this.alertify.error("Kindly provide the required fields");
        }

    }


    //Registration Data
    userData(): AuthRegister {
        let obj = this.registrationForm.getRawValue();
        console.log(obj);
        return this.authRegister = {
            username: obj.userName,
            email: obj.email,
            password: obj.password
        }
    }

    //Login data
    userLoginData(): AuthLogin {
        let obj = this.loginForm.getRawValue();
        return this.AuthLogin = {
            email: obj.userName,
            password: obj.password
        }
    }

}
interface UserError {
    error: number;
    code: string;
    description: string;
  }

  interface UserResponse {
    succeeded: boolean;
    errors: UserError[];
  }
