import { Component, OnInit } from '@angular/core';
import { PostsService } from '../../services/posts.service';
import { SubCategory } from '../../model/subcategory';
import { Category } from '../category/category';
import { SubCategoryService } from '../../services/subcategory.service';
import { CategoryService } from '../../services/category.service';
import { SystemService } from '../../services/system.service';

@Component({
    selector: 'app-blog-user',
    templateUrl: './blog-user.component.html',
    styleUrls: ['./blog-user.component.scss']
})
export class BlogUserComponent implements OnInit {

    searchTerm: any;
    isLoading = false;
    isUser: boolean;
    posts: any[];filteredTypeCardlistposts: any;
    categories: Category[];
    subcategories: SubCategory[];
    apiPath: any;


    constructor(private postsService: PostsService, private SubcategoryService: SubCategoryService,
        private categoryService: CategoryService,public service: SystemService) { this.loadCategories(); }

    ngOnInit() {
        this.isLoading = true;
        this.isUser = true;
        this.bindpost();
        this.apiPath = this.service.Settings.Base_API_URL;
        // this.postsService.geAllPostCall().subscribe(
        //     post_data => {
        //         this.posts = post_data.reverse();
        //         this.filteredTypeCardlistposts = this.posts;
        //         console.log(JSON.stringify(this.posts));
        //     }, error => {
        //         console.log('error');
        //     }
        // )
        // this.isLoading = false;
    }

    async bindpost()
    {
        this.isLoading = true;
        console.log(this.isLoading);
        this.posts = await this.postsService.geAllPostCall();
        console.log(JSON.stringify(this.posts));
        this.filteredTypeCardlistposts = this.posts;
        this.isLoading = false;
    }

        //******************************************SEARCH********************************************/
        onSearchTermChange(): void {
            // Update the filtered list based on the search term
            console.log(JSON.stringify(this.posts));
            this.filteredTypeCardlistposts = this.posts.filter(obj =>
               obj.title.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
        //******************************************SEARCH********************************************/


    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;
            console.log(JSON.stringify(this.categories));
            // Example usage
            const searchIds = [40, 42]; // Replace with the IDs you want to search
            //const resultCategoryNames = getCategoryNamesById(searchIds);
            //console.log(resultCategoryNames);

        });
    }

    getCategoryNames(category: string): string {
        // Convert category string to an array of category names
        const categoryIds = category.split(',').map(Number);
        const categoryNames = this.categories
          .filter(item => categoryIds.includes(item.categoryId))
          .map(item => item.categoryName);

        return categoryNames.join(', ');
      }


}
