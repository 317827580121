import { Documenttype } from './../../model/documenttype';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentTypeService } from '../../services/documenttype.service';



@Component({
    selector: 'app-documenttype-form',
    templateUrl: './documenttype.html',
    styleUrls: ['./documenttype.css'],
})
export class DocumenttypeFormComponent implements OnInit {
    documenttypeForm: FormGroup;
    documenttype: any;
    selecteddocumenttype: Documenttype | null = null;


    constructor(private fb: FormBuilder, private documenttypeService: DocumentTypeService) {
        this.loaddocument();
    }

    ngOnInit() {
        this.documenttypeForm = this.fb.group({
            DocumentTypeId: 0,
            DocumentName: ['', Validators.required],
            DocumentDescp: ['', Validators.required],

        });
    }



    loaddocument() {
        this.documenttypeService.getAlldocument().then(document => {
            this.documenttype = document;
            console.log(JSON.stringify(this.documenttype));
        });
    }

    async onSubmit() {
        const documenttype: Documenttype = this.documenttypeForm.value;

        if (documenttype.DocumentTypeId) {
            // Update existing documenttype
            let res = this.documenttypeService.updateDocumenttype(documenttype);
            if (res) {
                this.loaddocument(); // Refresh the documenttype list
            }

        } else {
            // Add new documenttype
            let res = this.documenttypeService.addDocumenttype(documenttype);
            if (res) {
                console.log(JSON.stringify(res));
                this.loaddocument(); // Refresh the documenttype list
            }
        }

        // Reset the form
        this.documenttypeForm.reset();
        this.selecteddocumenttype = null;
    }

    onEdit(documenttype: Documenttype) {
        // Load the selected documenttype into the form for editing
        this.selecteddocumenttype = this.convertKeysToCamelCase(documenttype);
        console.log(this.selecteddocumenttype);
        this.documenttypeForm.patchValue(this.selecteddocumenttype);
    }
    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
    onDelete(documenttypeId: number) {
        let res = this.documenttypeService.deleteDocumenttype(documenttypeId);
        if (res) {
            this.loaddocument(); // Refresh the documenttype list
        }
    }


}


export { Documenttype };

