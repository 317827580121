// FilterHeader.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { FilterHeaderModel } from '../model/filterHeader';


@Injectable({
    providedIn: 'root',
})
export class FilterHeaderService {
    apiUrl: any;
    BearerToken: any;
    FilterHeader: FilterHeaderModel[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllFilterHeader(): Promise<FilterHeaderModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<FilterHeaderModel[]>("FilterHeaderName");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching FilterHeader:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }


    async getFilterHeaderById(id: number): Promise<FilterHeaderModel> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("FilterHeaderName/{id}?filterId=" + id);
          } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
          }
        //return this.http.get<FilterHeaderModel>(`${this.apiUrl}/api/FilterHeaderName/${id}`);
    }

    async addFilterHeader(FilterHeader: FilterHeaderModel) {
        FilterHeader.filterId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("FilterHeaderName", FilterHeader);
        return res;
    }

    async updateFilterHeader(FilterHeader: FilterHeaderModel): Promise<FilterHeaderModel> {
        try {
            return await this.service.Data.ExecuteAPI_Put("FilterHeaderName",`${FilterHeader.filterId}?filterid=${FilterHeader.filterId}`, FilterHeader);
          } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
          }
    }

    async deleteFilterHeader(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("FilterHeaderName", `{id}?filterid=${id}`);
         return res;
    }
}
