import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SystemService } from './components/services/system.service';


@Injectable()
export class AuthGuard implements CanActivate {
    public defered = new Deferred<boolean>();
    public lastURL: string;
    constructor(public router: Router, public service: SystemService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.defered = new Deferred<boolean>();
        this.lastURL = state.url;
        console.log(route.data);
        this.service.HasAccountData.then(() => {
            window.setTimeout(() => {
                console.log(this.service.Account);
                if (this.service.App.getCookie("Bearer") && this.service.Account.userId.length > 1) {
                    console.log(route.data.isAdminshow);
                    this.defered.resolve(true);
                    console.log("pageProp true " + true);
                }
                else {
                    this.defered.resolve(false);
                    console.log("Redirect To Login--" + this.lastURL);
                    this.service.redirectToLogin(this.lastURL);
                    console.log("redirectToLogin false " + false);
                }
            }, 10);
        }, () => {
            this.defered.resolve(false);
            console.log("Redirect To Login--" + this.lastURL);
            this.service.redirectToLogin(this.lastURL);
            console.log(" Exit redirectToLogin false " + false);
        });
        console.error("details:", this.defered.promise);
        // Logging the promise details when it settles
        this.defered.promise.then(
            resolvedValue => console.log("Promise resolved:", resolvedValue),
            rejectedReason => console.log("Promise rejected:", rejectedReason)
        );
        return this.defered.promise;
    }
}

class Deferred<T> {

    promise: Promise<T>;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}
