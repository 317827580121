import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubCategory } from '../../model/subcategory';
import { SubCategoryService } from '../../services/subcategory.service';
import { CategoryService } from '../../services/category.service';
import { AlertifyService } from '../../services/alertify.service';
import { Category } from '../../model/category';


@Component({
    selector: 'app-Subcategory-form',
    templateUrl: './Subcategory.html',
    styleUrls: ['./Subcategory.css'],
})
export class SubCategoryFormComponent implements OnInit {
    SubcategoryForm: FormGroup;
    subcategories: any;
    categories: any;
    categoryIds: any;
    categotyList: any;
    selectedsubcategory: SubCategory | null = null;
    gridApi: any;
    rowData: any;
    @ViewChild('blogContainer2') blogContainer: ElementRef

    // @Output() subcategories = new EventEmitter<any>();

    constructor(private fb: FormBuilder, private renderer: Renderer2, private SubcategoryService: SubCategoryService, private categoryService: CategoryService, private alertify: AlertifyService,) {
        this.loadCategories();
        this.loadsubcategories();
    }

    ngOnInit() {
        this.SubcategoryForm = this.fb.group({
            subcategoryId: 0,
            categoryId: ['', Validators.required],
            subcategoryName: ['', Validators.required],
            subcategoryDescp: [''],

        });
        this.refreshGridData();
    }

    refreshGridData(): void {
        this.loadCategories();
        this.loadsubcategories();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }

    onMaterialGroupChange(event) {
        this.categoryIds = event;
    }

    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            console.log(JSON.stringify(categories));
            this.categotyList = categories;
        });
    }

    loadsubcategories() {
        this.SubcategoryService.getAllSubCategories().then(subcategories => {
            this.subcategories = subcategories;
            console.log(JSON.stringify(this.subcategories));
        });
    }


    async onSubmit() {
        this.scrollIntoViewAdd(this.blogContainer.nativeElement);
        if (this.SubcategoryForm.valid) {
            const subcategory: SubCategory = this.SubcategoryForm.value;
            subcategory.categoryId = this.selectedCategoryId;

            if (subcategory.subcategoryId) {
                console.log("Update existing subcategory");
                try {
                    let res = await this.SubcategoryService.updateSubCategory(subcategory);
                    console.log(JSON.stringify(res));

                    this.alertify.success("Updated Successfully");
                    this.refreshGridData();
                    this.SubcategoryForm.reset();
                } catch (error) {
                    console.error('Error updating subcategory:', error);

                }
            } else {
                console.log("Add new subcategory");
                try {
                    subcategory.subcategoryId = 0;
                    let res = await this.SubcategoryService.addSubCategory(subcategory);
                    this.alertify.success("Created Successfully");
                    console.log(JSON.stringify(res));

                    this.refreshGridData();

                } catch (error) {
                    console.error('Error adding subcategory:', error);

                }
            }


            this.SubcategoryForm.reset();
            this.selectedsubcategory = null;
        }
    }
    scrollIntoViewAdd(element: any): void {
        const gridElement = document.querySelector('.blog-details-area'); // Assuming the grid is under the class 'blog-details-area'

        if (gridElement) {
            const totalHeight = gridElement.scrollHeight;
            const scrollTopValue = totalHeight - element.offsetTop;
            this.renderer.setProperty(document.documentElement, 'scrollTop', scrollTopValue);
        }
    }


    // Inside your component
    selectedCategoryId: number;

    // Inside your onEdit method
    onEdit(subcategory: SubCategory) {
        //this.loadCategories();
        //this.selectedsubcategory = subcategory;
        //console.log(this.selectedsubcategory);
        this.SubcategoryForm.patchValue(subcategory);
        // this.SubcategoryForm.controls['categoryId'].setValue(subcategory.categoryId)
        // console.log(this.selectedCategoryId);
        console.log(this.selectedCategoryId);
        this.scrollIntoViewEdit();
    }


    scrollIntoViewEdit(): void {
        const gridElement = document.querySelector('.blog-details-area') as HTMLElement;

        if (gridElement) {
            gridElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    async onDelete(subcategoryId: number) {
        console.log(subcategoryId, "ressubcategory");
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            try {
                let res = await this.SubcategoryService.deleteSubCategory(subcategoryId);
                console.log(res, "ressubcategory");

                // Wait for the deletion to complete before refreshing data
                this.alertify.success("Deleted Successfully");
                await this.loadsubcategories();
                this.refreshGridData();


            } catch (error) {
                console.error('Error deleting subcategory:', error);

            }
        }
        this.scrollIntoViewDelete();
    }


    scrollIntoViewDelete(): void {
        const gridElement = document.querySelector('.blog-details-area') as HTMLElement;

        if (gridElement) {
            gridElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

}

