import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TypeService } from '../../services/type.services';
import { AlertifyService } from '../../services/alertify.service';
import { Router } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { SystemService } from '../../services/system.service';
import { DatePipe } from '@angular/common';
import { Questionnaire, QuestionnaireModel } from '../../model/question';
import { QuestionnaireService } from '../../services/questionnaire.service';

@Component({
    selector: 'app-questionnaire-add',
    templateUrl: './questionnaire-add.component.html',
    styleUrls: ['./questionnaire-add.component.scss'],
    providers: [DatePipe],
})
export class QuestionnaireAddComponent implements OnInit {
    questionnaireForm: FormGroup;
    typelist: any[] = [];
    selectedtype: QuestionnaireModel | null = null;
    categories: any;
    subcategories: any;
    category: any;
    subcategoty: any;
    languageOptions = [
        { code: '1', name: 'English' },
        { code: '2', name: 'Spanish' },
        { code: '3', name: 'French' },

    ];
    LanguageId: any;
    constructor(private fb: FormBuilder, private typeservices: TypeService, private questionnaireService: QuestionnaireService,
        private categoryService: CategoryService, private router: Router, private alertify: AlertifyService,
        private SubcategoryService: SubCategoryService, public service: SystemService) { }

    ngOnInit() {
        this.initializeForm();
        this.loadCategories();
        this.loadSubcategory();

    }

    initializeForm() {
        this.questionnaireForm = this.fb.group({
            Id: [null], // You may want to add validation as needed
            Identifier: [null, Validators.required],
            Name: [null, Validators.required],
            Description: [null],
            CategoryNameHyphenated: [null, Validators.required],
            CategoryId: [null, Validators.required],
            SubcategoryId: [null, Validators.required],
            LanguageId: [null, Validators.required],
            UserId: [],
            CreatedDate: [],
            Active: [true],
        });
    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    loadCategories() {
        try {
            this.categoryService.getAllCategories().then(categories => {
                this.categories = categories;
            });
        } catch (error) {
            console.error("Error loading categories:", error);
            // Handle the error, e.g., show a user-friendly message
        }
    }

    loadSubcategory() {
        try {
            this.SubcategoryService.getAllSubCategories().then(subcategories => {
                this.subcategories = subcategories;

            });
        } catch (error) {
            console.error("Error loading subcategories:", error);
            // Handle the error, e.g., show a user-friendly message
        }
    }


    async onSubmit() {
        try {
            const questionnaire: QuestionnaireModel = this.questionnaireForm.value;
            let obj = this.questionnaireForm.getRawValue();
            console.log(obj);
            obj.Id = 0;
            obj.UserId = localStorage.getItem("id");
            obj.CategoryId = obj.CategoryId;
            obj.SubcategoryId = obj.SubcategoryId.map(String).join(',');
            obj.Active = true;
            console.log(obj);
            let res = this.questionnaireService.addQuestionnaires(obj);
            console.log(res);
            if (res) {
                this.alertify.success("Data Saved Successfully");
                this.router.navigate(['/questionnairelist']);
            }
            // Reset the form
            this.questionnaireForm.reset();
            this.selectedtype = null;
        } catch (error) {
            // Handle errors here
            console.error("Error submitting questionnaire:", error);
            this.alertify.error("An error occurred while saving data.");
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }
}


