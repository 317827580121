<app-navbar-style-user *ngIf="isUser"></app-navbar-style-user>
<div class="banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content banner-content-ptb text-center">

                    <form (ngSubmit)="onSubmit()" [formGroup]="addBlogForm">
                        <div class="form-group">
                            <label class="font-weight-bold">Blog Title</label>
                            <input type="text" class="form-control editTitle" placeholder="Give your blog a title" formControlName="Title">
                            <span *ngIf="!Title.valid && (Title.touched)" class="error-block">
                              Please provide a Title
                            </span>
                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold">Description</label>
                            <textarea class="form-control editBody" placeholder="Write your blog" formControlName="Description"></textarea>
                            <!-- <angular-editor formControlName="Description" [config]="editorConfig"> </angular-editor> -->
                            <span *ngIf="!Description.valid && (Description.touched)" class="error-block">
                              Please provide a Description
                            </span>
                        </div>
                        <div class="form-group">
                          <label class="font-weight-bold">Blog Banner</label>
                          <input type="file" (change)="onFileSelect($event)" class="form-control-file" id="exampleFormControlFile1" formControlName="Banner_Image">
                          <img class="imageUpload" [src]="imageUrl" />
                        </div>
                        <div class="form-group">
                            <button [disabled]="addBlogForm.invalid" type="submit"  class="default-btn"> Save </button>
                        </div>
                        <div class="form-group">
                          <button type="reset"  class="default-btn"> Cancel </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two *ngIf="isUser"></app-footer-style-two>



