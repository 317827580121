import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { SystemService } from '../../services/system.service';
import { Documenttype } from '../documenttype/documenttype';
import { TypeModel } from '../../model/type';
import { QuestionsService } from '../../services/questions.service';
import { QuestionnaireService } from '../../services/questionnaire.service';

@Component({
    selector: 'app-questions-list',
    templateUrl: './questions-list.component.html',
    styleUrls: ['./questions-list.component.scss'],
    providers: [DatePipe],
})
export class QuestionsListComponent implements OnInit {
    QuestionsForm: FormGroup;
    type: TypeModel; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    data: any;
    isLoading = false;
    @Input() documenttype: Documenttype[] = [];
    @Output() editDocumenttype = new EventEmitter<Documenttype>();
    @Output() deleteDocumenttype = new EventEmitter<number>();

    constructor(private fb: FormBuilder, private questionnaireService: QuestionnaireService,
        private router: Router, private questionService: QuestionsService, private alertify: AlertifyService,
        public service: SystemService) { }

    ngOnInit() {
        this.binddataQuestions();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [

        {
            headerName: 'Id', field: 'Id', sortable: true, filter: true, width: 20, headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true
        },
        { headerName: 'QuestionnaireId', field: 'QuestionnaireId', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'QuestionType', field: 'QuestionType', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Identifier', field: 'Identifier', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Headline', field: 'Headline', sortable: true, filter: true, editable: true, width: 150 },
        //{ headerName: 'Description', field: 'description', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Required', field: 'Required', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Multiple', field: 'Multiple', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Multiline', field: 'Multiline', sortable: true, filter: true, editable: true, width: 100 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 180
        },
    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,

    };

    rowData: any;

    binddata() {
        this.service.App.ShowLoader = true;
        this.questionnaireService.getAllQuestionnaires().then(data => {
            console.log(data);
            this.rowData = data;
        });
        this.service.App.ShowLoader = false;
    }



    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    imageCellRenderer(params: any) {
        return `<img src="${params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    actionsCellRenderer(params: any) {
        const addButton = document.createElement('button');
        addButton.innerHTML = 'Add';
        addButton.classList.add('Actions-btn'); // Apply the CSS class
        addButton.addEventListener('click', () => this.addRow());

        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));


        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        wrapper.appendChild(addButton);
        wrapper.appendChild(deleteButton);


        return wrapper;
    }

    onSelectionChanged(event: any) {
        const selectedNodes = event.api.getSelectedNodes();

        if (selectedNodes.length > 0) {
          // At least one row is selected
          const selectedNode = selectedNodes[0];
          const selectedData = selectedNode.data;
          console.log('Row selected:', selectedData);
          this.router.navigate(['/choiceformadd', selectedData.Id]);
        } else {
          // No rows are selected
          console.log('Row deselected');
        }
    }


    addRow() {
        this.router.navigate(['/questionsadd']);
    }
    editRow(rowData: any) {
        console.log('Edit row:', rowData);
        let id = rowData.Id;
        this.router.navigate(['/questionsedit', id]);

    }
    deleteRow(rowData: any) {
        const confirmation = window.confirm('Are you sure you want to delete?');

        if (confirmation) {
            let res = this.questionService.deleteQuestion(rowData.id).then(res => {
                if (res) {
                    this.binddataQuestions();
                    this.alertify.success("Deleted Successfully");
                } else {
                    this.alertify.error("Try again! or Contact admin!");
                }
                console.log(JSON.stringify(res));
            }).catch(error => {
                console.error("Error deleting questions:", error);
                this.alertify.error("An error occurred while deleting the data.");
            });
        } else {
            // User canceled the deletion
            console.log('Deletion canceled by user.');
        }
    }

    async binddataQuestions() {
        try {
            this.service.App.ShowLoader = true;
            const data = await this.questionService.getAllQuestion();
            console.log(data);
            this.data = data;
            this.rowData = data;
            this.alertify.success("Data Loaded Successfully");
        } catch (error) {
            console.error("Error loading questions:", error);
            // Handle the error, e.g., show a user-friendly message
            this.alertify.error("An error occurred while loading data.");
        } finally {
            this.service.App.ShowLoader = false;
        }
    }



}

