<app-navbar-style-admin></app-navbar-style-admin>

<div class="inner-banner inner-bg5">
    <div class="container">

        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="inner-banner-text">
                        <h3>Blog</h3>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <!-- <li><i class='bx bx-chevron-right'></i></li>
                                <li>Pages</li> -->
                                <li><i class='bx bx-chevron-right'></i></li>
                                <li>Blog</li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="banner-tag">
                        <ul>
                            <li><a routerLink="/blog-details-add"><i class="flaticon-writing"></i> Add Post</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="banner-profile">
                        <!-- <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3> -->
                        <div class="banner-status">
                            <a routerLink="/listing-details"><h3>Admin</h3></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <!-- <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>







<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Latest Blog <b>Post</b></h2>
            <div>
                <div class="banner-bottom">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="banner-tag">
                                <ul>
                                    <li><a routerLink="/blog-details-add"><i class="flaticon-favorite"></i>Add Post</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12">
                    <div class="listing-right-form">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <div class="form-group">
                                        <i class='flaticon-loupe'></i>
                                        <input type="text" class="form-control" placeholder="What Are Searching*" style="border: 1px solid var(--bs-orange);"
                                            [(ngModel)]="searchTerm" (input)="onSearchTermChange()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pt-45">
            <div *ngIf="!filteredTypeCardlistposts || filteredTypeCardlistposts.length === 0">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="section-title text-center">
                        <span>Please wait, We're loading some exciting content for you</span>
                        </div>
                        <div class="spinner" style="background-color: var(--mainColor);"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" *ngFor="let post of filteredTypeCardlistposts">
                <div class="blog-card">
                    <a routerLink="/blog-details-user-view/{{post.id}}"><img [src]="this.apiPath + '/Documents/appImages/' + post.bannerImage" alt="Images">
                    </a>
                    <div class="content">
                        <span> {{post.postedOn}} / <a href="#">{{ getCategoryNames(post.category) }}</a></span>
                        <h3><a routerLink="/blog-details-user-view/{{post.id}}">{{post.title}}</a></h3>
                        <a routerLink="/blog-details-user-view/{{post.id}}" class="read-more">Read More</a>
                         <a routerLink="/blog-details-modify/{{post.id}}" class="read-more">Modify</a>
                    </div>
                </div>
            </div>
            <div *ngIf="isLoading" class="overlay-inner">
                <i class="fa fa-spinner fa-spin"></i>
            </div>


            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/blog" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog" class="page-numbers">2</a>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
