import { AuthRegister } from './../model/authregister';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
private apiPath: string;
constructor(private http: HttpClient,public service: SystemService) { this.apiPath=this.service.Settings.Base_API_URL }

// addUser(user: User){
//   let users = [];
//   if(localStorage.getItem('Users')) {
//     users = JSON.parse(localStorage.getItem('Users'));
//     users = [...users, user];
//   } else {
//     users = [user];
//   }
//   localStorage.setItem('Users', JSON.stringify(users));
// }

addUser(authRegister: AuthRegister) {
  console.log("Here");
  return this.http.post(this.apiPath + '/api/registeruser', authRegister);
}

banUser(id:number) {
  console.log("Here");
  return this.http.put(this.apiPath + '/api/User/ban/'+id, id);
}

adminUser(id:number) {
  console.log("Here");
  return this.http.put(this.apiPath + '/api/User/admin/'+id, id);
}

getUserByName(name:string): Observable<string[]> {
  console.log("Here");
  return this.http.get<string[]>(this.apiPath + '/api/User/get/'+name);
}

}
