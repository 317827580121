<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/login-img.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-5 col-xl-6">
                <div class="user-section text-center">
                    <div class="user-content">
                        <a href="/home"><img src="assets/img/logo/logo2.png" alt="Logo" width="100px"></a>
                        <h2>Reset Password</h2>
                    </div>

                    <div class="tab user-tab">
                        <ul class="tabs">
                            <li><a href="#" id="forgetpasswordTab"> <i class="bi bi-house-lock"></i> Reset Password</a>
                            </li>
                        </ul>
                        <div class="tab_content current active">
                            <div class="tabs_item current">
                                <div class="tabs_item">
                                    <div class="user-all-form">
                                        <div class="contact-form">
                                            <form class="pt-3" [formGroup]="ResetPasswordForm"
                                                (ngSubmit)="ResetPasswordForm.valid && ResetPassword()" #f="ngForm"
                                                novalidate>
                                                <div class="form-group mb-3" style="font-size:14px;">
                                                    <strong>{{UserName}}</strong>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="text" formControlName="email" id="email"
                                                            class="form-control" required
                                                            data-error="Please enter your Email" placeholder="email">
                                                        <span *ngIf="!email.valid && (email.touched)"
                                                            class="error-block">
                                                            Please provide a email
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt' *ngIf="!isPasswordPresent"></i>
                                                        <input class="form-control"
                                                            type="{{ showPassword ? 'text' : 'password' }}"
                                                            formControlName="password" placeholder="Password"
                                                            (change)="onPasswordChange()">
                                                        <div (click)="togglePasswordVisibility()"
                                                            *ngIf="isPasswordPresent">
                                                            <i
                                                                [ngClass]="{'bi-eye': showPassword, 'bi-eye-slash': !showPassword}"></i>
                                                        </div>
                                                    </div>
                                                    <span
                                                    *ngIf="ResetPasswordForm.hasError('passwordsNotMatch')">
                                                    Passwords do not match.
                                                </span>
                                                <small *ngIf="!ResetPasswordForm.get('password').touched"
                                                    class="form-text text-muted">
                                                    *minimum 8 charachters
                                                </small>
                                                <span
                                                    *ngIf="!ResetPasswordForm.get('password').valid && (ResetPasswordForm.get('password').touched || userSubmitted)"
                                                    class="error-block">
                                                    <span
                                                        *ngIf="ResetPasswordForm.get('password').errors['required']">
                                                        Please provide a password
                                                    </span>
                                                    <span *ngIf="ResetPasswordForm.get('password').errors.minlength">
                                                        Password should not be less than 8 characters
                                                    </span>
                                                </span>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt' *ngIf="!isPasswordPresent2"></i>
                                                        <input class="form-control"
                                                            type="{{ showPassword2 ? 'text' : 'password' }}"
                                                            formControlName="confirmPassword" placeholder="Password"
                                                            (change)="onPasswordChange2()">
                                                        <div (click)="togglePasswordVisibility2()"
                                                            *ngIf="isPasswordPresent2">
                                                            <i
                                                                [ngClass]="{'bi-eye': showPassword2, 'bi-eye-slash': !showPassword2}"></i>
                                                        </div>
                                                        <span
                                                        *ngIf="ResetPasswordForm.hasError('passwordsNotMatch')">
                                                        Passwords do not match.
                                                    </span>
                                                    <span
                                                        *ngIf="!ResetPasswordForm.get('ConfirmPassword').valid && (ResetPasswordForm.get('ConfirmPassword').touched || userSubmitted)"
                                                        class="error-block">
                                                        <span
                                                            *ngIf="ResetPasswordForm.get('ConfirmPassword').hasError('required')">
                                                            Please confirm the password
                                                        </span>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="mt-5">
                                                    <button [disabled]="isLoading"
                                                        class="btn btn-block btn-warning btn-lg font-weight-medium"
                                                        type="submit">
                                                        <i *ngIf="isLoading" class="fa fa-circle-notch fa-spin"></i>
                                                        Reset Password
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
