

<div class="banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content banner-content-ptb text-center">
                    <p>INDIANS IN</p>
                    <h1 class="margin-auto">KOSICE <b> Amazing City</b></h1>
                    <!-- <div class="banner-form">
                        <form>
                            <div class="row">
                                <div class="col-lg-2 col-md-4">
                                    <div class="form-group">
                                        <i class='flaticon-place'></i>
                                        <input class="form-control" type="text" placeholder="Location">
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <i class='flaticon-vision'></i>
                                        <input class="form-control" type="text"  placeholder="What are looking for?">
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group">
                                        <i class='flaticon-category'></i>
                                        <select class="form-control">
                                            <option>All Categories</option>
                                            <option>Restaurants</option>
                                            <option>Events</option>
                                            <option>Hotels</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                                    <button type="submit" class="default-btn">Search <i class="flaticon-loupe"></i></button>
                                </div>
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="banner-img"><img src="assets/img/home-three/home-three2.png" alt="Images"></div>
</div>

<section class="process-area process-bg2">
    <div class="process-into pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Process</span>
                <h2>See <b>How It Works</b></h2>
                <p>To support and empower Indians living in Košice</p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Community</h3>
                            <p style="text-align: justify;font-size: small;">Košice is a relatively small city, and the Indian community there is even smaller. This can make it difficult for Indians living in Košice to feel connected to their culture and community. We provide a space for Indians living in Košice to connect with each other, share information and support, and celebrate their culture.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Information</h3>
                            <p style="text-align: justify;font-size: small;">We help to promote Indian culture and heritage in Košice by providing information about Indian festivals, traditions, and food. We also host articles and blog posts about Indian culture, and feature interviews with Indian people living and working in Košice.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Empowerment</h3>
                            <p style="text-align: justify;font-size: small;">We provide support and empowerment to Indians living in Košice by providing information about resources and services available to them, and by sharing stories of successful Indians living in Košice. We also host a forum where Indians living in Košice can ask questions, share advice, and support each other.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section>

<section class="category-box-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/category"><i class="flaticon-bake"></i></a>
                    <h3><a routerLink="/category">Restaurant</a></h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/category"><i class="flaticon-hotel"></i></a>
                    <h3><a routerLink="/category">University</a></h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/category"><i class="flaticon-barbell"></i></a>
                    <h3><a routerLink="/category">Fitness</a></h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/category"><i class="flaticon-event"></i></a>
                    <h3><a routerLink="/category">Events</a></h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/category"><i class="flaticon-store"></i></a>
                    <h3><a routerLink="/category">Shops</a></h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/category"><i class="flaticon-flower"></i></a>
                    <h3><a routerLink="/category">Places</a></h3>
                </div>
            </div>


        </div>
    </div>
</section>

<section class="city-area-two pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="section-title">
                    <span>Cities</span>
                    <h2>Explore Best Cities In Area</h2>
                    <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor incididunt </p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="city-btn">
                    <a routerLink="/" class="default-btn">View all places <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area5.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Fort Worth</h3></a>
                        <span>17 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area6.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>San Antonio</h3></a>
                        <span>13 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area7.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Milwaukee</h3></a>
                        <span>13 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-6">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area8.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>United Kingdom</h3></a>
                        <span>30 Locations</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 offset-md-3 offset-lg-0">
                <div class="city-card">
                    <a routerLink="/"><img src="assets/img/city-area/city-area9.jpg" alt="Images"></a>
                    <div class="content">
                        <a routerLink="/"><h3>Turkey</h3></a>
                        <span>34 Locations</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <section class="place-list-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Places Lists</span>
            <h2>Latest <b>Listings Added</b></h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor </p>
        </div>
    </div>

    <div class="container-fluid">
        <div class="place-slider-three  owl-carousel owl-theme pt-45">
            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Restaurant</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Billiard Restaurant</h3></a>
                    <p><i class="flaticon-cursor"></i> Dorente rio, 104, 00184 Crono, Canada</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list2.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile2.png" alt="Images">
                        <h3>By, Jaein</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"> <a routerLink="/listing-details">Beauty Shop</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Beauty Shop</h3></a>
                    <p><i class="flaticon-cursor"></i> Davisto Laterani, 104, 00184 Roma, Italy</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">5.0</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list3.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile3.png" alt="Images">
                        <h3>By, Normand</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Fitness Club</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Ridge Fitness Club</h3></a>
                    <p><i class="flaticon-cursor"></i> Ke visto Onterio, 104, 6789 Rcona, usa</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.5</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list4.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile4.png" alt="Images">
                        <h3>By, Olfred</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Hotel</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Hotel</h3></a>
                    <p><i class="flaticon-cursor"></i> Ostapin, 134, 00184 Coventry, London</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.7</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Restaurant</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Billiard Restaurant</h3></a>
                    <p><i class="flaticon-cursor"></i> Dorente rio, 104, 00184 Crono, Canada</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="video-area-two">
    <div class="container">
        <div class="video-option">
            <a href="https://www.youtube.com/watch?v=KHwA8ufCf4Y" class="video-btn">
                <i class='bx bx-play play-video'></i>
                <span><i class='flaticon-arrow'></i> Play Video</span>
            </a>
        </div>
    </div>
</div>

<div class="counter-area-three">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">1254</h3>
                    <span>New Visiters Every Week</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">23165</h3>
                    <span>New Visiters Every Week</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">4563</h3>
                    <span>Won Amazing Awards</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">880</h3>
                    <span>New Listing Every Week</span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="place-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="section-title mb-45">
                    <span>Desire Places</span>
                    <h2>Most <b>Popular Places</b></h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="place-btn">
                    <a routerLink="/listing" class="default-btn">Check out all places <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area1.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile.png" alt="Images">
                            <h3>By, Lowis Jelda</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Dongo 184 Crono, Canada</span>
                        <a routerLink="/listing-details"><h3>Denisto Centin Restaurant </h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"> <a routerLink="/">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area2.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>30 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile2.png" alt="Images">
                            <h3>By, Austin Deli</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 40 Square Plaza, NJ, USA</span>
                        <a routerLink="/listing-details"><h3>Iconic Cafe in Ontario</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$500-700</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area3.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-heliotrope">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile3.png" alt="Images">
                            <h3>By, Polin Osto</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/listing-details"><h3>Strong body Gym</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/listing-details">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area4.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.8</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile4.png" alt="Images">
                            <h3>By, Debit Jhon</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 27th Brooklyn New York, USA</span>
                        <a routerLink="/listing-details"><h3>Family Convenience Store</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area5.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile5.png" alt="Images">
                            <h3>By, Kelvin Sasi</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 56 Street Square Plaza, NJ, USA</span>
                        <a routerLink="/listing-details"><h3>Iconic Cafe in Onterio</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$300-600</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area6.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>39 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-green">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile6.png" alt="Images">
                            <h3>By, Creiun Hitler</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/listing-details"><h3>Kentorin Hotel</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="client-area">
    <div class="container">
        <div class="client-bg">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Oli Rubion</h3>
                                <span>Rubion Inc</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Sanaik Tubi</h3>
                                <span>Arbon Restaurant</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Mashrof Ruin</h3>
                                <span>Pice Cafe</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Download app</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/mobile2.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->


