import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { filterHeaderValueModel } from '../model/filterHeaderValue';

@Injectable({
    providedIn: 'root',
})
export class FilterHeaderValueService {
    apiUrl: any;
    BearerToken: any;
    FilterHeader: filterHeaderValueModel[] = [];
    httpOptions: { headers: any; };

    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllFilterHeaderValue(): Promise<filterHeaderValueModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<filterHeaderValueModel[]>("FilterHeaderValue");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching FilterHeader:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }


    async getFilterHeaderValueById(id: number): Promise<filterHeaderValueModel> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("FilterHeaderValue/{id}?filterHedaerValueId=" + id);
          } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
          }
        //return this.http.get<filterHeaderValueModel>(`${this.apiUrl}/api/FilterHeaderValue/${id}`);
    }

    async addFilterHeaderValue(FilterHeader: filterHeaderValueModel) {
        FilterHeader.filterHedaerValueId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("FilterHeaderValue", FilterHeader);
        return res;
    }

    async updateFilterHeaderValue(FilterHeader: filterHeaderValueModel): Promise<filterHeaderValueModel> {
        try {
            return await this.service.Data.ExecuteAPI_Put("FilterHeaderValue",`${FilterHeader.filterHedaerValueId}?filterHedaerValueId=${FilterHeader.filterHedaerValueId}`, FilterHeader);
          } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
          }
    }

    async deleteFilterHeaderValue(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("FilterHeaderValue", `{id}?filterHedaerValueId=${id}`);
         return res;
    }
}
