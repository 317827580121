<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor); border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create FilterHeader</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="filterHeaderform" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label for="filterHeaderName1">FilterHeader Name:</label>
                                    <input type="text" id="filterHeaderName1" class="form-control"
                                        formControlName="filterHeaderName1">
                                    <div *ngIf="filterHeaderform.get('filterHeaderName1')?.touched && filterHeaderform.get('filterHeaderName1') && filterHeaderform.get('filterHeaderName1').hasError('required')"
                                        class="text-danger">
                                        Field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="filterHeaderDescp">FilterHeader Description:</label>
                                    <input type="text" id="filterHeaderDescp" class="form-control"
                                        formControlName="filterHeaderDescp">
                                    <div *ngIf="filterHeaderform.get('filterHeaderDescp')?.touched && filterHeaderform.get('filterHeaderDescp') && filterHeaderform.get('filterHeaderDescp').hasError('required')"
                                        class="text-danger">
                                        Field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block"
                                        [disabled]="!filterHeaderform.valid">Save</button>
                                </div>
                                <div class="form-group text-center">
                                    <button routerLink="/filterHeaderlist" class="btn btn-primary btn-block">
                                        Back</button>
                                </div>
                            </form>

                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>





<app-footer-style-two></app-footer-style-two>
