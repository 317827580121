import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { SubCategory } from '../model/subcategory';


@Injectable({
    providedIn: 'root',
})
export class SubCategoryService {
    apiUrl: any;
    BearerToken: any;
    categories: SubCategory[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


  

    async getAllSubCategories(): Promise<SubCategory[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<SubCategory[]>("SubCategory");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching categories:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

    getSubCategoryById(id: number): Observable<SubCategory> {
        return this.http.get<SubCategory>(`${this.apiUrl}/api/SubCategory/${id}`);
    }

    async addSubCategory(subcategory: SubCategory) {
        let res = await this.service.Data.ExecuteAPI_Post("SubCategory", subcategory);
        return res;
    }

    async updateSubCategory(subcategory: SubCategory): Promise<Observable<SubCategory>> {
        let res = await this.service.Data.ExecuteAPI_Put("SubCategory",`${subcategory.subcategoryId}?subcategoryid=${subcategory.subcategoryId}`, subcategory);
        return res;
    }

    async deleteSubCategory(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("SubCategory", `${id}?subcategoryid=${id}`);
         return res;
    }
}
