import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeService } from '../../services/type.services';
import { AlertifyService } from '../../services/alertify.service';
import { Router } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { SystemService } from '../../services/system.service';
import { DatePipe } from '@angular/common';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { QuestionModel } from '../../model/question';
import { QuestionsService } from '../../services/questions.service';

@Component({
    selector: 'app-questions-add',
    templateUrl: './questions-add.component.html',
    styleUrls: ['./questions-add.component.scss'],
    providers: [DatePipe],
})
export class QuestionsAddComponent implements OnInit {
    QuestionsForm: FormGroup;
    typelist: any[] = [];
    selectedtype: QuestionModel | null = null;
    categories: any;
    subcategories: any;
    category: any;
    subcategoty: any;
    QuestionnaireId: any;
    Questionnaires: any;
    QuestionType: any;
    questionTypes = [
        'multiple-choice',
        'text'
      ];

    constructor(private fb: FormBuilder, private typeservices: TypeService, private questionnaireService: QuestionnaireService,
        private categoryService: CategoryService, private questionsService: QuestionsService, private router: Router,
        private SubcategoryService: SubCategoryService, public service: SystemService,private alertify: AlertifyService) { }

    ngOnInit() {
        this.initializeForm();
        this.binddata();

    }

    initializeForm() {
        this.QuestionsForm = this.fb.group({
            Id: [null],
            QuestionnaireId: [null, Validators.required],
            QuestionType: [null, Validators.required],
            Identifier: [null, Validators.required],
            Headline: [null, Validators.required],
            Description: [null],
            Required: [false],
            Multiple: [false],
            Multiline: [false],
            UserId: [],
            CreatedDate: [],
            Active: []
        });
    }


    binddata() {
        this.service.App.ShowLoader = true;
        this.questionnaireService.getAllQuestionnaires().then(data => {
            console.log(data);
            this.Questionnaires = data;
        });
        this.service.App.ShowLoader = false;
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }


    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;

        });
    }

    loadSubcategory() {
        this.SubcategoryService.getAllSubCategories().then(subcategories => {
            this.subcategories = subcategories;

        });

    }

    loadtypes() {
        this.typeservices.getAllTypelist().then(typelist => {
            this.typelist = typelist;
            console.log(JSON.stringify(this.typelist));
        });
    }

    async onSubmit() {
        try {
            let obj = this.QuestionsForm.getRawValue();
            console.log(obj);
            obj.Id = 0;
            obj.UserId = localStorage.getItem("id");
            obj.QuestionnaireId = obj.QuestionnaireId;
            obj.Active = true;
            obj.QuestionType=this.QuestionType;
            // Assuming addQuestion returns a promise
            let res = await this.questionsService.addQuestion(obj);

            if (res) {
                this.router.navigate(['/questionslist']);
                this.alertify.success("Question added successfully");
            } else {
                this.alertify.error("Failed to add question");
            }

        } catch (error) {
            console.error("Error adding question:", error);
            this.alertify.error("An error occurred while adding the question.");
        } finally {
            // Reset the form
            this.QuestionsForm.reset();
            this.selectedtype = null;
        }
    }


    onDelete(typeId: number) {
        let res = this.typeservices.deleteType(typeId);
        if (res) {
            this.loadtypes(); // Refresh the type list
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        //console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        //console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        //console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        //console.log('blur', $event)
        this.focused = false
        this.blured = true
    }
}


