import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Gallery } from '../../model/gallery';
import { GalleryService } from '../../services/gallery.service';

@Component({
    selector: 'app-gallery-edit',
    templateUrl: './gallery-edit.component.html',
    styleUrls: ['./gallery-edit.component.scss'],
    providers: [DatePipe],
})
export class GalleryEditComponent implements OnInit {
    galleryform: FormGroup;
    gallery: Gallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    id: any;
    documentTypeId: any;
    typeId: any;
    galleryrecord: any;
    isLoading = false;
    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private galleryService: GalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService,private route: ActivatedRoute) {

            this.id = this.route.snapshot.paramMap.get('id');

         }



    ngOnInit() {
        this.Creategalleryform();
        this.loadDocumentTypeService();
        this.loadTypeService();
        this.loadGalleryId();
    }

    loadGalleryId() {
        console.log(this.id);
        this.isLoading = true;
        this.galleryService.getGallery(this.id).then(Gallerydata => {
            this.galleryrecord = Gallerydata;
            this.galleryform.patchValue(Gallerydata);
            const typeIds = Gallerydata.typeId;
            const documentTypeIds = Gallerydata.documentTypeId;
            //**************************************PatchValue */
            this.typeId = typeIds;
            this.documentTypeId = documentTypeIds;
            this.imageUrl = Gallerydata.galleryPhoto;
            this.isLoading = false;
        });

    }

    loadDocumentTypeService() {
        this.documentTypeService.getAlldocument().then(documentTypelist => {
            this.documentTypelist = documentTypelist;

        });
    }

    loadTypeService() {
        this.typeService.getAllTypelist().then(typelist => {
            this.typelist = typelist;

        });

    }


    onSubmit() {
        this.userSubmitted = true;
        let obj = this.galleryform.getRawValue();
        console.log(JSON.stringify(this.gallery));
        obj.GalleryId = this.galleryrecord.galleryId == null ? 0 : this.galleryrecord.galleryId;
        obj.GalleryPhoto = this.imageUrl ? this.imageUrl : obj.GalleryPhoto;
        console.log(JSON.stringify(obj));
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
        obj.Extra1 = this.fileName;
        console.log("Update");
        console.log(JSON.stringify(obj));
        if (obj.GalleryId) {
            obj.UpdatedDate =formattedDate;
            obj.Updatedby = this.service.Account.UserID;
            obj.UpdatedDate = formattedDate;
            obj.isActive = true;
            obj.status = true;
            console.log("Update");
            let res = this.galleryService.updateGallery(this.convertKeysToCamelCase(obj));
            if (res) {
                this.galleryform.reset();
                this.router.navigate(['/gallerylist']);
            }
        }

        this.userSubmitted = false;

    }


    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Creategalleryform() {
        this.galleryform = this.fb.group({
            GalleryId: [null],
            TypeId: [null, Validators.required],
            DocumentTypeId: [null, Validators.required],
            GalleryPhoto: [null, Validators.required],
            GalleryBuget: [null],
            Createdby: [null],
            CreatedDate: [null],
            Updatedby: [null],
            UpdatedDate: [null],
            Description: [null],
            Extra1: [null],
            Extra2: [null],
            Extra3: [null],
            Extra4: [null],
            Extra5: [null],
            Extra6: [null],
            Extra7: [null],
            Extra8: [null],
            Extra9: [null],
            Extra10: [null],
            isActive: [true],
            Status: [null],
        });
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }

    cancel() {
        this.galleryform.reset();
        this.router.navigate(['/gallerylist']);
    }
}

