
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { ChoiceModel } from '../model/question';




@Injectable({
    providedIn: 'root'
})
export class QChoiceService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    //Add ChoiceModels
    async addChoice(data: ChoiceModel): Promise<Observable<ChoiceModel>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Choice", data);
        console.log(res);
        return res;
    }

    async updateChoice(data: ChoiceModel): Promise<Observable<ChoiceModel>> {
        let res = await this.service.Data.ExecuteAPI_Put("Choice", `${data.Id}?id=${data.Id}`, data);
        return res;
    }

    //Get All ChoiceModels
    async getAllChoice(): Promise<ChoiceModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<ChoiceModel[]>("Choice");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id ChoiceModels
    async getChoiceById(id: number): Promise<ChoiceModel> {
        try {
          return await this.service.Data.ExecuteAPI_Get<ChoiceModel>("Choice/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async getChoiceQuestionById(id: number): Promise<any[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<any[]>("Choice/questionId/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async deleteChoice(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("Choice", `${id}?id=${id}`);
    }

}
