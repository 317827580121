<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/login-img.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-5 col-xl-6">
                <div class="user-section text-center">
                    <div class="user-content">
                        <a href="/home"><img src="assets/img/logo/logo2.png" alt="Logo" width="100px"></a>
                        <h2>Welcome to BackOffice</h2>
                    </div>
                    <div class="tab user-tab">
                        <ul class="tabs">
                            <li><a href="#" id="loginTab"> <i class="flaticon-contact"></i> Login</a></li>
                            <!-- <li><a href="#" id="registerTab"> <i class="flaticon-verify"></i> Register</a></li> -->
                            <li><a href="#" id="forgetpasswordTab"> <i class="bi bi-house-lock"></i> Forget Password</a>
                            </li>
                        </ul>
                        <div class="tab_content current active">
                            <div class="tabs_item current">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form id="contactForm"  [formGroup]="loginForm" (ngSubmit)="onLogin()">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="text" ngModel formControlName="email" id="name"
                                                            class="form-control" required
                                                            data-error="Please enter your Email"
                                                            placeholder="Email">
                                                        <span
                                                            *ngIf="!loginForm.get('email').valid && (loginForm.touched || userSubmitted)"
                                                            class="error-block">
                                                            Please provide a username
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt' *ngIf="!isPasswordPresent3"></i>
                                                        <input class="form-control"
                                                            type="{{ showPassword3 ? 'text' : 'password' }}"
                                                            formControlName="password" placeholder="Password"
                                                            (change)="onPasswordChange3()">
                                                        <div (click)="togglePasswordVisibility3()" *ngIf="isPasswordPresent3">
                                                            <i [ngClass]="{'bi-eye': showPassword3, 'bi-eye-slash': !showPassword3}"></i>
                                                        </div>
                                                    </div>
                                                    <small *ngIf="!loginForm.get('password').touched"
                                                        class="form-text text-muted">
                                                        *minimum 8 characters and with Capital letter and Special Char.
                                                    </small>
                                                    <span
                                                        *ngIf="!loginForm.get('password').valid && (loginForm.get('password').touched || userSubmitted)"
                                                        class="error-block">
                                                        <span
                                                            *ngIf="loginForm.get('password').errors['required']">
                                                            Please provide a password
                                                        </span>
                                                        <span *ngIf="loginForm.get('password').errors.minlength">
                                                            Password should not be less than 8 characters
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" [disabled]="!loginForm.valid"
                                                        class="default-btn  user-all-btn">Login</button>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 form-condition">
                                                    <div class="agree-label">
                                                        <input type="checkbox" id="chb1">
                                                        <label for="chb1">Remember Me</label>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-6 col-sm-6">
                                                    <a class="forget" routerLink="/">Forgot my password?</a>
                                                </div> -->
                                            </div>
                                        </form>
                                        <div class="social-option">
                                            <h3>Connect with us</h3>
                                            <ul>
                                                <li><a
                                                        href="https://www.facebook.com/groups/281998441315023">Facebook</a>
                                                </li>
                                                <!-- <li><a href="#">Twitter</a></li>
                                                <li><a href="#">Linkedin</a></li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="tabs_item">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form id="contactForm" [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="userName" id="name"
                                                            class="form-control" required
                                                            data-error="Please enter your Username"
                                                            placeholder="Username">
                                                        <span
                                                            *ngIf="!registrationForm.get('userName').valid && (registrationForm.get('userName').touched || userSubmitted)"
                                                            class="error-block">
                                                            Please provide a username
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="email" formControlName="email" id="email"
                                                            class="form-control" required
                                                            data-error="Please enter email" placeholder="Email">
                                                        <small *ngIf="!registrationForm.get('email').touched"
                                                            class="form-text text-muted">
                                                            We'll never share your email with anyone else.
                                                        </small>
                                                        <span
                                                            *ngIf="!registrationForm.get('email').valid && (registrationForm.get('email').touched || userSubmitted)"
                                                            class="error-block">
                                                            <span
                                                                *ngIf="registrationForm.get('email').hasError('required')">
                                                                Please provide an email
                                                            </span>
                                                            <span
                                                                *ngIf="registrationForm.get('email').hasError('email')">
                                                                Please provide valid email
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt' *ngIf="!isPasswordPresent"></i>
                                                        <input class="form-control"
                                                            type="{{ showPassword ? 'text' : 'password' }}"
                                                            formControlName="password" placeholder="Password"
                                                            (change)="onPasswordChange()">
                                                        <div (click)="togglePasswordVisibility()"
                                                            *ngIf="isPasswordPresent">
                                                            <i
                                                                [ngClass]="{'bi-eye': showPassword, 'bi-eye-slash': !showPassword}"></i>
                                                        </div>
                                                    </div>
                                                    <span
                                                        *ngIf="registrationForm.hasError('passwordsNotMatch')">
                                                        Passwords do not match.
                                                    </span>
                                                    <small *ngIf="!registrationForm.get('password').touched"
                                                        class="form-text text-muted">
                                                        *minimum 8 characters and with Capital letter and Special Char.
                                                    </small>
                                                    <span
                                                        *ngIf="!registrationForm.get('password').valid && (registrationForm.get('password').touched || userSubmitted)"
                                                        class="error-block">
                                                        <span
                                                            *ngIf="registrationForm.get('password').errors['required']">
                                                            Please provide a password
                                                        </span>
                                                        <span *ngIf="registrationForm.get('password').errors.minlength">
                                                            Password should not be less than 8 characters
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt' *ngIf="!isPasswordPresent2"></i>
                                                        <input class="form-control"
                                                            type="{{ showPassword2 ? 'text' : 'password' }}"
                                                            formControlName="ConfirmPassword" placeholder="Password"
                                                            (change)="onPasswordChange2()">
                                                        <div (click)="togglePasswordVisibility2()"
                                                            *ngIf="isPasswordPresent2">
                                                            <i
                                                                [ngClass]="{'bi-eye': showPassword2, 'bi-eye-slash': !showPassword2}"></i>
                                                        </div>
                                                        <span
                                                            *ngIf="registrationForm.hasError('passwordsNotMatch')">
                                                            Passwords do not match.
                                                        </span>
                                                        <span
                                                            *ngIf="!registrationForm.get('ConfirmPassword').valid && (registrationForm.get('ConfirmPassword').touched || userSubmitted)"
                                                            class="error-block">
                                                            <span
                                                                *ngIf="registrationForm.get('ConfirmPassword').hasError('required')">
                                                                Please confirm the password
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn">Register
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p class="account-desc">Already have an account? <a
                                                            routerLink="/">Login</a></p>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="social-option">
                                            <h3>Or Register With</h3>
                                            <ul>
                                                <li><a
                                                        href="https://www.facebook.com/groups/281998441315023">Facebook</a>
                                                </li>
                                                 <li><a href="#">Twitter</a></li>
                                                <li><a href="#">Linkdin</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="tabs_item">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form id="contactForm" [formGroup]="forgetPasswordForm"
                                            (ngSubmit)="onSubmitforgetPassword()">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="email" id="email"
                                                            class="form-control" required
                                                            data-error="Please enter your Email" placeholder="email">
                                                        <span *ngIf="!forgetPasswordForm.get('email').valid && (forgetPasswordForm.get('email').touched || userSubmitted)"
                                                            class="error-block">
                                                            Please provide a email
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn">Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
