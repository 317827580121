
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterHeaderModel } from '../../model/filterHeader';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { Router } from '@angular/router';
import { filterHeaderSearchModel } from './filterHeader_listSearch';
import { SystemService } from '../../services/system.service';
import { FilterHeaderSearchService } from '../../services/filterHeaderSearch.service';
import { AlertifyService } from '../../services/alertify.service';
@Component({
    selector: 'app-filterHeader_addSearch-form',
    templateUrl: './filterHeader_addSearch.html',
    styleUrls: ['./filterHeader_addSearch.css'],
})
export class FilterHeaderAddSearchformComponent implements OnInit {
    filterHeader: filterHeaderSearchModel;
    isLoading = false;
    filterHeaderSearchForm: FormGroup;
    allfilterheaderAllData: any;
    allfilterheaderNames: any;
    allfilterheaderValues: any;
    allfilterheaderTypes: any;
    selectedfilterName: any;
    selectedfilterValue: any;
    selectedfilterType: any;



    constructor(private router: Router, private fb: FormBuilder,
        public service: SystemService, private alertify: AlertifyService, public filterHeaderSearchservices: FilterHeaderSearchService) { }


    ngOnInit() {
        this.FilterHeaderSearchForm();
        this.loadfilterHeaderAllData();
        this.loadfilterHeaderNames();
        this.loadfilterHeaderValues();
        this.loadfilterHeaderTypes();
    }

    FilterHeaderSearchForm() {
        this.filterHeaderSearchForm = this.fb.group({
            searchId: 0,
            filterNameId: ['', Validators.required],
            filterValueId: ['', Validators.required],
            typeId: ['', Validators.required],
            // description: ['']
        });
    }

    onMaterialGroupChange(event: any) {

    }



    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'SearchId', sortable: true, filter: true, width: 20 },
        { headerName: 'Filter Name', field: 'FilterNameId', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'Filter Value', field: 'FilterValueId', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Type', field: 'TypeId', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Date', field: 'CreatedDate', sortable: true, filter: true, editable: true, width: 200 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }


    actionsCellRenderer(params: any) {
        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        wrapper.appendChild(deleteButton);
        return wrapper;
    }

    addRow() {
        this.router.navigate(['/filterHeaderadd']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.filterId;
        this.router.navigate(['/filterHeaderedit', id]);

    }
    deleteRow(rowData: any) {
        let res = this.filterHeaderSearchservices.deleteFilterHeaderSearch(this.rowData.filterId);
        console.log(JSON.stringify(res));

    }

    loadfilterHeaderAllData() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderAllData().then(allfilterheaderAllData => {
            this.allfilterheaderAllData = allfilterheaderAllData;
            console.log(JSON.stringify(this.allfilterheaderAllData));
        });
        this.service.App.ShowLoader = false;
    }

    loadfilterHeaderNames() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderName().then(allfilterheaderNames => {
            this.allfilterheaderNames = allfilterheaderNames;
            this.rowData = allfilterheaderNames;
            console.log(JSON.stringify(this.allfilterheaderNames));
        });
        this.service.App.ShowLoader = false;
    }

    loadfilterHeaderValues() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderValue().then(allfilterheaderValues => {
            this.allfilterheaderValues = allfilterheaderValues;
            this.rowData = allfilterheaderValues;
            console.log(JSON.stringify(this.allfilterheaderValues));
        });
        this.service.App.ShowLoader = false;
    }

    loadfilterHeaderTypes() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderType().then(allfilterheaderTypes => {
            this.allfilterheaderTypes = allfilterheaderTypes;
            this.rowData = allfilterheaderTypes;
            console.log(JSON.stringify(this.allfilterheaderTypes));
        });
        this.service.App.ShowLoader = false;
    }

    addFilterHeader() {
        this.router.navigate(['/filterHeaderadd']);
    }

    async onSubmit() {

        if (this.filterHeaderSearchForm.valid) {
            let obj = this.filterHeaderSearchForm.getRawValue();
            console.log(JSON.stringify(obj));
            obj.filterName = obj.typeId.toString();
            let res = this.filterHeaderSearchservices.addFilterHeaderSearch(this.convertKeysToCamelCase(obj));
            if (res) {
                this.alertify.success("Data Saved Successfully");
                this.router.navigate(['/filterHeaderlistSearch']);
            }
            // Reset the form
            this.filterHeaderSearchForm.reset();
            //this.selectedCategoryId = null;
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
}


export { filterHeaderSearchModel };
