<style>
    .bg {

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: var(--titleColor);
        border-radius: 8px;
    }
</style>
<div class="container mt-4">
    <h2>Dashboard</h2>
    <div class="row mt-3">
        <!-- Card 5 -->
        <div class="col-md-3 mb-3">
            <div class="card bg">
                <a routerLink="/filterHeaderlist" class="card-link">
                    <div class="card-body">
                        <h5 class="card-title">Headers Name</h5>
                        <p class="card-text">Headers/Filters Details.</p>
                    </div>
                </a>
            </div>
        </div>

        <!-- Card 5 -->
        <div class="col-md-3 mb-3">
            <div class="card bg">
                <a routerLink="/filterHeaderlistValue" class="card-link">
                    <div class="card-body">
                        <h5 class="card-title">Headers Value</h5>
                        <p class="card-text">Headers/Filters Details.</p>
                    </div>
                </a>
            </div>
        </div>

        <!-- Card 5 -->
        <div class="col-md-3 mb-3">
            <div class="card bg">
                <a routerLink="/filterHeaderlistSearch" class="card-link">
                    <div class="card-body">
                        <h5 class="card-title">Headers Search</h5>
                        <p class="card-text">Headers/Filters Details.</p>
                    </div>
                </a>
            </div>
        </div>

    </div>
    <div class="row mt-3">

        <!-- Card 1 -->
        <div class="col-md-3 mb-3">
            <div class="card bg">
                <a routerLink="/profiles" class="card-link">
                    <div class="card-body">
                        <h5 class="card-title">User</h5>
                        <p class="card-text">User Details.</p>
                    </div>
                </a>
            </div>
        </div>
        <!-- Card 4 -->
        <div class="col-md-3 mb-3">
            <div class="card bg">
                <a routerLink="/profiles" class="card-link">
                    <div class="card-body">
                        <h5 class="card-title">Profiles</h5>
                        <p class="card-text">Profiles Details.</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="row mt-3">
            <!-- Card 6 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/bannerlist" class="card-link">
                        <div class="card-body">
                            <h5 class="card-title">Type Banner</h5>
                            <p class="card-text">Banner Details.</p>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Card 2 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/typelist" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Type</h5>
                            <p class="card-text">Type Details.</p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/profiles" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Type Comments</h5>
                            <p class="card-text">Type Comments Details.</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <!-- Card 8 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/gallerylist" class="card-link">
                        <div class="card-body">
                            <h5 class="card-title">Gallery</h5>
                            <p class="card-text">Gallery Details.</p>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Card 2 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a href="/blog-user" class="card-link">
                        <div class="card-body">
                            <h5 class="card-title">Blog</h5>
                            <p class="card-text">Blog Details.</p>
                        </div>
                    </a>
                </div>
            </div>

              <!-- Card 3 -->
              <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/profiles" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Blog Comments</h5>
                            <p class="card-text">Blog Comments Details.</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <!-- Category Card 1 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a href="/mastercategory" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title"> Category</h5>
                            <p class="card-text"> Category Details.</p>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Sub Category Card 1 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a href="/mastersubcategory" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Sub Category</h5>
                            <p class="card-text">Sub Category Details.</p>
                        </div>
                    </a>
                </div>
            </div>

            <!--Document Card 7 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/documenttype" class="card-link">
                        <div class="card-body">
                            <h5 class="card-title">Document Type</h5>
                            <p class="card-text">Document Type Details.</p>
                        </div>
                    </a>
                </div>
            </div>




        </div>
        <div class="row mt-3">
            <!-- Card 1 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a href="/questionnairelist" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Questionnaire</h5>
                            <p class="card-text"> Questionnaire Details.</p>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Card 1 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a href="/questionslist" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Question</h5>
                            <p class="card-text">Question Details.</p>
                        </div>
                    </a>
                </div>
            </div>

            <!-- Card 3 -->
            <div class="col-md-3 mb-3">
                <div class="card bg">
                    <a routerLink="/response" class="card-link">

                        <div class="card-body">
                            <h5 class="card-title">Response</h5>
                            <p class="card-text">Response Details.</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>


    </div>
</div>
<div class="container mt-4">
    <br>
</div>
