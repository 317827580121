<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/adminPanel" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
    </div>

    <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/adminPanel"><img src="assets/img/logo/logo2.png" alt="Logo" width="100px"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/adminPanel" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Admin Panel  <i class='bx bx-plus'></i></a>
                        </li>

                        <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/category" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Category</a>
                                </li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-plus'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Shop</a></li>

                                        <li class="nav-item"><a routerLink="/cart" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                        <li class="nav-item"><a routerLink="/checkout" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                        <li class="nav-item"><a routerLink="/products-details" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/login-register" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Login/Register</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms &
                                        Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                        Policy</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coming
                                        Soon</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li> -->
                    </ul>
                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact" *ngIf="userName"></i>

                            </div>
                        </div>
                        <div class="side-item">
                            <div class="search-box">
                                <span *ngIf="userName">Welcome, {{userName}}</span>
                            </div>
                        </div>

                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/login-register" *ngIf="!userName" class="default-btn">Log in<i class='bx bx-plus'></i></a>
                                <button class="default-btn" *ngIf="userName" (click)="logout()">Log Out<i class='bx bx-plus'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="cart-btn">
                                <i class="flaticon-contact"></i>  <span>{{userName}}</span>
                            </div>
                        </div>


                        <div class="side-item">
                            <div class="nav-add-btn">
                                        <a routerLink="/login-register" *ngIf="!userName" class="default-btn border-radius">Log in<i class='bx bx-plus'></i></a>
                                        <a routerLink="/adminPanel" class="default-btn" *ngIf="userName">Log Out<i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>
            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
