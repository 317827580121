
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterHeaderModel } from '../../model/filterHeader';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-filterHeader_list-form',
    templateUrl: './filterHeader_list.html',
    styleUrls: ['./filterHeader_list.css'],
})
export class FilterHeaderListformComponent implements OnInit {

    filterHeader: FilterHeaderModel;
    isLoading = false;
    allfilterheader: any;


    constructor(private router: Router,
        public service: SystemService, public filterHeaderservices: FilterHeaderService, private alertify: AlertifyService) {
            this.loadfilterHeader();
    }


    ngOnInit() {
        this.refreshGridData();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'filterId', sortable: true, filter: true, width: 20 },
        { headerName: 'FilterHeaderName1', field: 'filterHeaderName1', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'FilterHeaderDescp', field: 'filterHeaderDescp', sortable: true, filter: true, editable: true, width: 200 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    refreshGridData(): void {
        this.loadfilterHeader();
        if (this.rowData) {
            this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
        }
    }

    actionsCellRenderer(params: any) {
        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        wrapper.appendChild(deleteButton);
        return wrapper;
    }

    addRow() {
        this.router.navigate(['/filterHeaderadd']);
    }

    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.filterId;
        this.router.navigate(['/filterHeaderedit', id]);

    }

    deleteRow(rowData: any) {

        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.filterHeaderservices.deleteFilterHeader(rowData.filterId);
            console.log(JSON.stringify(res));
            this.refreshGridData();
            this.alertify.success("Deleted Successfully");
        } else {
            console.log('Deletion canceled.');
        }

    }

    loadfilterHeader() {
        this.service.App.ShowLoader = true;
        this.filterHeaderservices.getAllFilterHeader().then(allfilterheader => {
            this.allfilterheader = allfilterheader;
            this.rowData = allfilterheader;
            console.log(JSON.stringify(this.allfilterheader));
            this.alertify.success("Data Saved Successfully");
        });
        this.service.App.ShowLoader = false;

    }

    addFilterHeader() {
        this.router.navigate(['/filterHeaderadd']);
    }


}


export { FilterHeaderModel };

