import { Component, OnInit } from '@angular/core';
import { PostsService } from '../../services/posts.service';
import { AddCommentService } from '../../services/addComment.service';
import { Blog } from '../../model/blog';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'app-navbar-style-user',
  templateUrl: './navbar-style-user.component.html',
  styleUrls: ['./navbar-style-user.component.scss']
})
export class NavbarStyleUserComponent implements OnInit {

    commentCount: any;
    userName = localStorage.getItem('token');
    userEmail = localStorage.getItem('email');
    posts: Blog[];
    postCount: number;

    constructor(private commentService: AddCommentService,
                private postService: PostsService,private router: Router,private services: SystemService) { }

    ngOnInit() {
      this.commentService.getUserCommentCount(this.userName).subscribe(
        comment => {
          this.commentCount = comment;
        }
      );

      this.postService.getUserPosts(this.userName).subscribe(
        posts => {
          this.posts = posts;
          this.postCount = posts.length;
        }
      );
    }

    logout()
    {
        console.log("logOut");
      this.services.logOut();
      localStorage.setItem('token', "");
      localStorage.setItem('email', "");
      localStorage.setItem('admin', "");
      localStorage.setItem('id', "");
      localStorage.clear();
      this.router.navigate(['/']);
    //   window.location.reload();
    }
}
