import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { TypeService } from '../../services/type.services';
import { CategoryService } from '../../services/category.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { QuestionsService } from '../../services/questions.service';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { QuestionModel } from '../../model/question';

@Component({
    selector: 'app-questions-edit',
    templateUrl: './questions-edit.component.html',
    styleUrls: ['./questions-edit.component.scss'],
    providers: [DatePipe],
})
export class QuestionsEditComponent implements OnInit {
    QuestionsForm: FormGroup;
    typelist: any[] = [];
    selectedtype: QuestionModel | null = null;
    categories: any;
    subcategories: any;
    category: any;
    subcategoty: any;
    id: string;
    isLoading: boolean = false;
    QuestionType: any;
    Questionnaires: any;QuestionnaireId: any;
    questionTypes = [
        'multiple-choice',
        'text'
      ];
    constructor(private fb: FormBuilder, private typeservices: TypeService, private questionnaireService: QuestionnaireService,
        private categoryService: CategoryService, private questionsService: QuestionsService, private router: Router,private route: ActivatedRoute,
        private SubcategoryService: SubCategoryService, public service: SystemService)
        {
            this.id = this.route.snapshot.paramMap.get('id');
         }

    ngOnInit() {
        this.initializeForm();
        this.binddata();
        this.bindQuestionsById();
    }

    initializeForm() {
        this.QuestionsForm = this.fb.group({
            Id: [null],
            QuestionnaireId: [null, Validators.required],
            QuestionType: [null, Validators.required],
            Identifier: [null, Validators.required],
            Headline: [null, Validators.required],
            Description: [null],
            Required: [false],
            Multiple: [false],
            Multiline: [false],
            UserId: [],
            CreatedDate: [],
            Active: []
        });
    }


    binddata() {
        this.service.App.ShowLoader = true;
        this.questionnaireService.getAllQuestionnaires().then(data => {
            console.log(data);
            this.Questionnaires = data;
        });
        this.service.App.ShowLoader = false;
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }


    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;

        });
    }

    loadSubcategory() {
        this.SubcategoryService.getAllSubCategories().then(subcategories => {
            this.subcategories = subcategories;

        });

    }

    loadtypes() {
        this.typeservices.getAllTypelist().then(typelist => {
            this.typelist = typelist;
            console.log(JSON.stringify(this.typelist));
        });
    }

    bindQuestionsById() {
        console.log(this.id);
        this.isLoading = true;
        this.questionsService.getQuestionById(Number(this.id)).then(data => {
            data = this.convertKeysToCamelCase(data);
            console.log(data);
            this.QuestionsForm.patchValue(data);
            const QuestionnaireId = data.QuestionnaireId;
            this.QuestionnaireId = QuestionnaireId;
            console.log(JSON.stringify(data));
            this.isLoading = false;
        });
    }


    async onSubmit() {
        const data: QuestionModel = this.QuestionsForm.value;
        if (data.Id) {
            let obj = this.QuestionsForm.getRawValue();
            const data: QuestionModel = this.QuestionsForm.value;
            console.log(obj);
            obj.UserId = localStorage.getItem("id");
            obj.QuestionnaireId = obj.QuestionnaireId;
            obj.Active = true;
            obj.QuestionType=this.QuestionType;
            let res = this.questionsService.updateQuestion(obj);
            if (res) {
                console.log(res);
                this.router.navigate(['/questionslist']);
            }
        }
        // Reset the form
        this.QuestionsForm.reset();
        this.selectedtype = null;
    }

    // onEdit(type: QuestionModel) {
    //     // Load the selected type into the form for editing
    //     console.log(JSON.stringify(JSON.stringify(type)));
    //     this.selectedtype = type;
    //     //**************************************PatchValue */
    //     type = this.convertKeysToCamelCase(type);
    //     this.QuestionsForm.patchValue(type);
    //     const Categorybind = type.CategoryId.split(',').map(Number);
    //     const SubCategorybind = type.SubcategoryId.split(',').map(Number);
    //     console.log(Categorybind);
    //     console.log(SubCategorybind);
    //     this.category = Categorybind;
    //     this.subcategoty = SubCategorybind;
    //     console.log(JSON.stringify(this.QuestionsForm.value));
    // }

    onDelete(typeId: number) {
        // let res = this.typeservices.deleteType(typeId);
        // if (res) {
        //     this.loadtypes(); // Refresh the type list
        // }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        //console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        //console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        //console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        //console.log('blur', $event)
        this.focused = false
        this.blured = true
    }
}


