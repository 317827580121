import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';

import { NavbarStyleTwoComponent } from './components/common/navbar-style-guest/navbar-style-guest.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';

import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertifyService } from './components/services/alertify.service';
import { AuthService } from './components/services/auth.service';
import { NewBlogService } from './components/services/newBlog.service';
import { PostsService } from './components/services/posts.service';
import { UserServiceService } from './components/services/user-service.service';
import { BlogCardComponent } from './components/admin/blog-card/blog-card.component';
import { AdminPanelComponent } from './components/admin/adminPanel/adminPanel.component';
import { CommentCardComponent } from './components/admin/comment-card/comment-card.component';
import { NewBlogComponent } from './components/admin/newBlog/newBlog.component';
import { ScrollToTopComponent } from './components/admin/scrollToTop/scrollToTop.component';
import { TableComponent } from './components/admin/table/table.component';
import { NavbarStyleAdminComponent } from './components/common/navbar-style-admin/navbar-style-admin.component';
import { NavbarStyleUserComponent } from './components/common/navbar-style-user/navbar-style-user.component';
import { HomeAdminComponent } from './components/pages/home-admin/home-admin.component';
import { BlogUserComponent } from './components/admin/blog/blog-user.component';
import { BlogDetailsViewComponent } from './components/admin/blog-details/blog-details-view.component';
import { SystemService } from './components/services/system.service';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { BlogDetailsModifyComponent } from './components/admin/blog-details/blog-details-modify.component';
import { BlogDetailsAddComponent } from './components/admin/blog-post/blog-details-add.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill'
import { CategoryFormComponent } from './components/admin/category/category';
import { SubCategoryFormComponent } from './components/admin/subcategory/subcategory';
import { CategoryGridComponent } from './components/admin/category/category-grid';
import { SubCategoryGridComponent } from './components/admin/subcategory/subcategory-grid';
import { ProfileFormComponent } from './components/pages/profile/profile';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { AuthGuard } from './auth.guard';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DocumenttypeGridComponent } from './components/admin/documenttype/documenttype-grid';
import { DocumenttypeFormComponent } from './components/admin/documenttype/documenttype';
import { GalleryAddComponent } from './components/admin/gallery/gallery-add.component';
import { GalleryListComponent } from './components/admin/gallery/gallery-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { GalleryEditComponent } from './components/admin/gallery/gallery-edit.component';
import { TypeListComponent } from './components/admin/type/type-list.component';
import { TypeEditComponent } from './components/admin/type/type-edit.component';
import { TypeAddComponent } from './components/admin/type/type-add.component';
import { BannerAddComponent } from './components/admin/banner/banner-add.component';

import { BannerListComponent } from './components/admin/banner/banner-list.component';
import { FilterHeaderAddformComponent } from './components/admin/filterHeader/filterHeader_add';
import { FilterHeaderEditformComponent } from './components/admin/filterHeader/filterHeader_edit';
import { FilterHeaderListformComponent } from './components/admin/filterHeader/filterHeader_list';
import { FilterHeaderAddSearchformComponent } from './components/admin/filterHeaderSearch/filterHeader_addSearch';
import { FilterHeaderEditSearchformComponent } from './components/admin/filterHeaderSearch/filterHeader_editSearch';
import { FilterHeaderListSearchformComponent } from './components/admin/filterHeaderSearch/filterHeader_listSearch';
import { FilterHeaderAddValueformComponent } from './components/admin/filterHeaderValues/filterHeader_addValue';
import { FilterHeaderEditValueformComponent } from './components/admin/filterHeaderValues/filterHeader_editValue';
import { FilterHeaderListValueformComponent } from './components/admin/filterHeaderValues/filterHeader_listValue';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireAddComponent } from './components/admin/questionnairefrm/questionnaire-add.component';
import { QuestionnaireEditComponent } from './components/admin/questionnairefrm/questionnaire-edit.component';
import { QuestionnaireListComponent } from './components/admin/questionnairefrm/questionnaire-list.component';
import { QuestionsListComponent } from './components/admin/questionfrm/questions-list.component';
import { QuestionsEditComponent } from './components/admin/questionfrm/questions-edit.component';
import { QuestionsAddComponent } from './components/admin/questionfrm/questions-add.component';
import { ChoiceFormComponent } from './components/admin/questionchoice/questionchoice';
import { QuestionChoiceGridComponent } from './components/admin/questionchoice/questionchoice-grid';
import { QuestiontypeFormComponent } from './components/admin/questiontype/questiontype';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BannerEditsComponent } from './components/admin/banner/banner-edits.component';
import { Reset_PasswordComponent } from './components/pages/login-register/reset_password';



@NgModule({
    declarations: [

        AppComponent,
        PreloaderComponent,
        NavbarStyleTwoComponent,
        FooterStyleTwoComponent,
        ErrorComponent,
        LoginRegisterComponent,
        ComingSoonComponent,
        BlogCardComponent,
        NewBlogComponent,
        CommentCardComponent,
        AdminPanelComponent,
        TableComponent,
        ScrollToTopComponent,
        NavbarStyleUserComponent,
        NavbarStyleAdminComponent,
        HomeAdminComponent,
        BlogUserComponent,
        BlogDetailsViewComponent,
        BlogDetailsModifyComponent,
        DashboardComponent,
        BlogDetailsAddComponent,
        CategoryFormComponent,
        SubCategoryFormComponent,
        CategoryGridComponent,
        SubCategoryGridComponent,
        ProfileFormComponent,
        DocumenttypeGridComponent,
        DocumenttypeFormComponent,
        GalleryAddComponent,
        GalleryListComponent,
        GalleryEditComponent,
        TypeListComponent,
        TypeEditComponent,
        TypeAddComponent,
        BannerAddComponent,
        BannerListComponent,

        FilterHeaderAddformComponent,
        FilterHeaderEditformComponent,
        FilterHeaderListformComponent,
        FilterHeaderAddSearchformComponent,
        FilterHeaderEditSearchformComponent,
        FilterHeaderListSearchformComponent,
        FilterHeaderAddValueformComponent,
        FilterHeaderEditValueformComponent,
        FilterHeaderListValueformComponent,
        QuestionnaireListComponent,
        QuestionnaireEditComponent,
        QuestionnaireAddComponent,
        QuestionsListComponent,
        QuestionsEditComponent,
        QuestionsAddComponent,
        ChoiceFormComponent,
        QuestionChoiceGridComponent,
        QuestiontypeFormComponent,
        BannerEditsComponent,
        Reset_PasswordComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes, { useHash: false, enableTracing: false }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        QuillModule.forRoot(),
        AgGridModule,
        NgbModule,
        ModalModule.forRoot(),
        FullCalendarModule
    ],
    providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }, AuthGuard, PostsService,
        UserServiceService,
        AlertifyService,
        AuthService,
        SystemService,
        NewBlogService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
