import { Component, OnInit } from '@angular/core';
import { Blog } from '../../model/blog';

import { PostsService } from '../../services/posts.service';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { Account_Model } from '../../model/common_model';

@Component({
    selector: 'app-navbar-style-guest',
    templateUrl: './navbar-style-guest.component.html',
    styleUrls: ['./navbar-style-guest.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

    commentCount: any;
    userName = localStorage.getItem('username');
    userEmail = localStorage.getItem('email');
    posts: Blog[];
    postCount: number;
    userID: string;
    Account: any;
    constructor(public service: SystemService,
        private postService: PostsService, private router: Router, private services: SystemService) { }

    ngOnInit(): void {

        this.userID = this.service.Account.userId;
        console.log("UserId Header NavBarTwo", JSON.stringify(this.userID));
        if (this.userID) {
            // Do nothing, or you can add specific actions if needed
            console.log("UserID is defined");
        } else {
            if (this.service.App.getCookie("Bearer")) {
                console.log("logOut");
                this.service.logOut();
            }
        }
    }

    logout() {
        console.log("logOut");
        this.services.logOut();
        localStorage.setItem('token', "");
        localStorage.setItem('email', "");
        localStorage.setItem('admin', "");
        localStorage.setItem('id', "");
        localStorage.clear();
        //console.log(this.service.App.getCookie("Bearer"));
        localStorage.clear();
        this.service.resetPromise();
        this.service.App.setCookie("Bearer", "", 0, "");
        localStorage.removeItem('isAdmin');
        this.Account = <Account_Model>{ UserID: 0, UserName: "" };
        console.log("redirectToLogin");
        this.service.redirectToLogin();
    }
}
