<!-- <style>
  #blogContainer2 {
    height: 500px; /* Set an appropriate height */
    overflow-y: auto; /* Enable vertical scrolling if the content exceeds the height */
  }
</style> -->

<app-navbar-style-admin></app-navbar-style-admin>

<div class="blog-details-area pt-50 pb-0" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
              <h4 class="card-title mt-2 text-center" style="color:white">Create Category</h4>
            </header>
            <article class="card-body">
              <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="categoryName">Category Name:</label>
                  <input type="text" id="categoryName" class="form-control" formControlName="categoryName" required>
                  <div
                    *ngIf="categoryForm.get('categoryName')?.hasError('required') && categoryForm.get('categoryName')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="categoryDescp">Category Description:</label>
                  <input type="text" id="categoryDescp" class="form-control" formControlName="categoryDescp" required>
                  <div
                    *ngIf="categoryForm.get('categoryDescp')?.hasError('required') && categoryForm.get('categoryDescp')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-success btn-block" [disabled]="!categoryForm.valid">Save</button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="blog-details-area pt-50 pb-70" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <article class="card-body">
              <!-- Grid section -->
              <app-category-grid [category]="category" (editCategory)="onEdit($event)"
                (deleteCategory)="onDelete($event)"></app-category-grid>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>
