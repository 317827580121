import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { filterHeaderSearchModel } from '../model/filterHeaderSearch';
import { filterHeaderValueModel } from '../model/filterHeaderValue';
import { FilterHeaderModel } from '../model/filterHeader';
import { TypeModel } from '../model/type';



@Injectable({
    providedIn: 'root',
})
export class FilterHeaderSearchService {
    apiUrl: any;
    BearerToken: any;
    FilterHeader: filterHeaderSearchModel[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }

    async getAllFilterHeaderValue(): Promise<filterHeaderValueModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<filterHeaderValueModel[]>("FilterHeaderValue");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching FilterHeader:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

      async getAllFilterHeaderName(): Promise<FilterHeaderModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<FilterHeaderModel[]>("FilterHeaderName");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching FilterHeader:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

      async getAllFilterHeaderType(): Promise<TypeModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<TypeModel[]>("TypeDetail");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Typelist:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }


    async getAllFilterHeaderAllData(): Promise<filterHeaderSearchModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<filterHeaderSearchModel[]>("FilterHeaderSearch");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching FilterHeader:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }


    async getFilterHeaderSearchById(id: number): Promise<filterHeaderSearchModel> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("FilterHeaderSearch/{id}?searchId=" + id);
          } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
          }
        //return this.http.get<filterHeaderSearchModel>(`${this.apiUrl}/api/FilterHeaderName1/${id}`);
    }

    async addFilterHeaderSearch(FilterHeader: filterHeaderSearchModel) {
        let res = await this.service.Data.ExecuteAPI_Post("FilterHeaderSearch", FilterHeader);
        return res;
    }

    async updateFilterHeaderSearch(FilterHeaderSearch: any): Promise<filterHeaderSearchModel> {
        try {
            console.log(FilterHeaderSearch)
            return await this.service.Data.ExecuteAPI_Put("FilterHeaderSearch",`${FilterHeaderSearch.SearchId}?searchId=${FilterHeaderSearch.SearchId}`, FilterHeaderSearch);
          } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
          }
    }

    async deleteFilterHeaderSearch(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("FilterHeaderSearch", `${id}?searchId=${id}`);
         return res;
    }
}
