
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { QuestionModel } from '../model/question';




@Injectable({
    providedIn: 'root'
})
export class QuestionsService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    //Add QuestionModels
    async addQuestion(data: QuestionModel): Promise<Observable<QuestionModel>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Question", data);
        console.log(res);
        return res;
    }

    async updateQuestion(data: QuestionModel): Promise<Observable<QuestionModel>> {
        let res = await this.service.Data.ExecuteAPI_Put("Question", `${data.Id}?id=${data.Id}`, data);
        return res;
    }

    //Get All QuestionModels
    async getAllQuestion(): Promise<QuestionModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<QuestionModel[]>("Question");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id QuestionModels
    async getQuestionById(id: number): Promise<QuestionModel> {
        try {
          return await this.service.Data.ExecuteAPI_Get<QuestionModel>("Question/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async deleteQuestion(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("Question", `${id}?id=${id}`);
    }

}
