import { Questionnaire, QuestionnaireModel } from './../model/question';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { Question, QuestionnaireResponse, ResponseModel } from '../model/question';


@Injectable({
    providedIn: 'root'
})
export class QuestionnaireService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    private collectedData: ResponseModel[] = [];

    addDataQuestions(data: any) {
       data = this.convertKeysToCamelCase(data);
        const dataValues: ResponseModel = {
            Id: 0,
            QuestionnaireId: data.QuestionnaireId,
            QuestionId: data.QuestionId,
            UserId: localStorage.getItem('id'),
            ResponseLabel: data.Label,
            ResponseValue: data.Value,
            ResponseDate: new Date(),
            TypeId: Number(localStorage.getItem('typeId')),
            CreatedDate: new Date(),
            Active: true,
        };
       // console.log(dataValues);
        this.collectedData.push(dataValues);
        console.log(JSON.stringify(this.collectedData));
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }


    getAllData() {
        return this.collectedData;
    }

    clearData() {
        this.collectedData = [];
    }

    //Add Response
    async addResponseModel() {
        let res = await this.service.Data.ExecuteAPI_Post("Response/ObjectResponses", this.collectedData);
        return res;
    }

    //Add Questionnaires
    async addQuestionnaires(data: QuestionnaireModel): Promise<Observable<QuestionnaireModel>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Questionnaire", data);
        console.log("RRR----" + JSON.stringify(res));
        return res;
    }

    async deleteQuestionnaires(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("Questionnaire",`${id}?id=${id}`);
    }

    async updateQuestionnaires(data: QuestionnaireModel): Promise<Observable<QuestionnaireModel>> {
        let res = await this.service.Data.ExecuteAPI_Put("Questionnaire", `${data.Id}?id=${data.Id}`, data);
        console.log("RRR Update----" + JSON.stringify(res));
        return res;
    }

    //Get All Questionnaires
    async getAllQuestionnaires(): Promise<QuestionnaireModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<QuestionnaireModel[]>("Questionnaire");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching FilterHeader:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id Questionnaires
    async getQuestionnairesById(id: number): Promise<QuestionnaireModel> {
        try {
          return await this.service.Data.ExecuteAPI_Get<QuestionnaireModel>("Questionnaire/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Questionnaire:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }



}
