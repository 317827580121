import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Comment } from '../model/comment';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class AddCommentService {
    private apiPath: string;
constructor(private http:HttpClient,public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL}

addComment(comment: Comment) {
  return this.http.post(this.apiPath + '/api/Comment/post', comment);
}

getComments(postId: number): Observable<Comment[]> {
  return this.http.get<Comment[]>(this.apiPath + '/api/Comment/findComment/'+postId);
}

getUserCommentCount(username:string) {
  return this.http.get(this.apiPath + '/api/Comment/userComment/'+username);
}

updateComment(id:number, newComment:string) {
  return this.http.put(this.apiPath + '/api/Comment/edit/'+id,{Id:id,newComment:newComment});
}

deleteComment(id:number) {
  return this.http.delete(this.apiPath + '/api/Comment/delete/'+id);
}

deleteAllComment(postId:number) {
  return this.http.delete(this.apiPath + '/api/Comment/deleteAll/'+postId);
}
}
