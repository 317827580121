// Profile.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { Profile } from '../model/profile';


@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    apiUrl: any;
    BearerToken: any;
    Profile: Profile[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllProfile(): Promise<Profile[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<Profile[]>("Profile");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching Profile:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    getProfileById(id: number): Observable<Profile> {
        return this.http.get<Profile>(`${this.apiUrl}/api/Profile/${id}`);
    }

    async getProfileByUserId(UserId: string)
    {
        try {
            let res = await this.service.Data.ExecuteAPI_Get<Profile>(`Profile/UserId/${UserId}`);
            console.log(JSON.stringify(res));
            return res;
        } catch (error) {
            console.error('Error fetching Profile:', error);
            return null; // or throw the error, depending on your error-handling strategy
        }
    }



    async addProfile(Profile: Profile): Promise<Observable<Profile>> {
        Profile.ProfileId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Profile", Profile);
        return res;
    }

    async updateProfile(Profile: Profile): Promise<Observable<Profile>> {
        let res = await this.service.Data.ExecuteAPI_Put("Profile", `${Profile.ProfileId}?Profileid=${Profile.ProfileId}`, Profile);
        return res;
    }

    async deleteProfile(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("Profile", `${id}?Profileid=${id}`);
        return res;
    }
}
