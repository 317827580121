import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from '../../model/blog';
import { AddCommentService } from '../../services/addComment.service';
import { AlertifyService } from '../../services/alertify.service';
import { BlogBannerService } from '../../services/blogBanner.service';
import { CommentBoxService } from '../../services/commentBox.service';
import { PostsService } from '../../services/posts.service';
import { DatePipe } from '@angular/common';
import { SubCategoryService } from '../../services/subcategory.service';
import { CategoryService } from '../../services/category.service';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'app-blog-details-view',
  templateUrl: './blog-details-view.component.html',
  styleUrls: ['./blog-details-view.component.scss'],
  providers: [DatePipe],
})
export class BlogDetailsViewComponent implements OnInit {
    isUser: boolean;isLoading = false;
    public postId: number;
    post: Blog;
    userSubmitted: boolean;
    addCommentForm: FormGroup;
    comment: Comment;
    comments: any[];
    currentUser = localStorage.getItem('token');
    isEdit = false;
    newTitle: string;
    newDescription: string;
    loggedinUser: string;
    admin: string;
    postdetails: any;
    category: any;
    subcategoty: any;
    addBlogForm: any;
    imageUrl: any;categories: any;categoryCountsArray: { name: string, count: number }[] = [];
    filteredTypeCardlistposts: any;

      constructor(private route: ActivatedRoute,
                  private postsService: PostsService,
                  private addCommentService: AddCommentService,
                  private fb: FormBuilder,
                  private commentBoxJS: CommentBoxService,
                  private blogaBannerService: BlogBannerService,
                  private alertify: AlertifyService,
                  private router: Router,
                  private categoryService: CategoryService,
                  private SubcategoryService: SubCategoryService,
                  private PostsService: PostsService, public service: SystemService) {  this.route.params.subscribe(params => {
                    this.postId = params['id'];
                    console.log('ID:', this.postId);
                    // Now you can use the 'id' as needed in your component logic
                }); }

      ngOnInit() {
        this.loadPost(this.postId);
        this.isUser = true;
        this.commentBoxJS.commentBox();
        this.loadCategories()

        //fetch comments
        this.fetchComments();
      }

      loadSideOrderCategories() {
        this.filteredTypeCardlistposts = this.postsService.getlocalAllPostCall();
        console.log(this.filteredTypeCardlistposts);

        // Initialize a Map to store category name counts
        const categoryCountsMap = new Map<string, number>();

        for (let post of this.filteredTypeCardlistposts) {
          console.log(post.category);

          // Get category names for the current post
          const postCategoryNames: { name: string, count: number }[] = this.getCategoryNames(post.category);

          // Count occurrences of each category name
          postCategoryNames.forEach(({ name }) => {
            categoryCountsMap.set(name, (categoryCountsMap.get(name) || 0) + 1);
          });
        }

        // Now, categoryCountsMap contains the counts for each category name
        // You can convert it to an array of "Name, Count" pairs
        categoryCountsMap.forEach((count, name) => {
          this.categoryCountsArray.push({ name, count });
        });

        console.log(this.categoryCountsArray);
      }

      getCategoryNames(category: string): { name: string, count: number }[] {
        // Convert category string to an array of category names
        const categoryIds = category.split(',').map(Number);
        const categoryNames = this.categories
          .filter(item => categoryIds.includes(item.categoryId))
          .map(item => item.categoryName);

        const countedCategoryNames = {};

        categoryNames.forEach(name => {
          countedCategoryNames[name] = (countedCategoryNames[name] || 0) + 1;
        });

        // Convert the countedCategoryNames object to an array of "Name, Count" pairs
        const categoryCountsArray: { name: string, count: number }[] = [];

        for (const name in countedCategoryNames) {
          if (countedCategoryNames.hasOwnProperty(name)) {
            const count = countedCategoryNames[name];
            categoryCountsArray.push({ name, count });
          }
        }

        return categoryCountsArray;
      }




      loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;
            console.log(JSON.stringify(this.categories));
            // Example usage
            const searchIds = [40, 42]; // Replace with the IDs you want to search
            //const resultCategoryNames = getCategoryNamesById(searchIds);
            //console.log(resultCategoryNames);
            this.loadSideOrderCategories();
        });
    }


      loadPost(Id: any) {
        this.isLoading = true;
        this.PostsService.getPost(Id).then(postdetails => {
            this.postdetails = postdetails;
            //console.log(JSON.stringify(this.postdetails));
            const Categorybind = postdetails.category.split(',').map(Number);
            console.log(Categorybind);
            const SubCategorybind = postdetails.subcategoty.split(',').map(Number);
            console.log(SubCategorybind);
            //**************************************PatchValue */
            this.category = Categorybind;
            this.subcategoty = SubCategorybind;
            //console.log(postdetails.tags);
            //this.addBlogForm.patchValue({ tags: postdetails.tags });
            //this.addBlogForm.patchValue({ description: postdetails.description });
            //this.addBlogForm.patchValue({ title: postdetails.title });
            this.imageUrl = postdetails.bannerImage;
            console.log(this.imageUrl);
            //this.addBlogForm.patchValue({ bannerImage: postdetails.bannerImage });
            //**************************************PatchValue */
            //this.addBlogForm.patchValue(postdetails);
            this.isLoading = false;
        });
        this.isLoading = false;
    }


      edit() {
        this.isEdit = true;
      }

      cancelUpdate() {
        this.isEdit = false;
      }

      updatePost(id: number) {
        // this.postsService.updatePost(id, this.newTitle, this.newDescription).subscribe(
        //   data => {
        //     this.isEdit = false;
        //     this.fetchpost();
        //   }
        // );

      }

      deletePost(id: number) {
        this.postsService.deletePost(id).subscribe(
          data => {
            this.addCommentService.deleteAllComment(id).subscribe(
              comment_data => {
                this.alertify.success("You have deleted the post");
                this.router.navigate(['/home']);
              }
            );
          }
        );
      }

      onSubmit() {
        this.userSubmitted = true;

        if(this.addCommentForm.valid) {
          this.addCommentService.addComment(this.commentData()).subscribe(
            data => {
              console.log(data);
              this.fetchComments();
            }
          );
          this.addCommentForm.reset();
          this.userSubmitted = false;

        }
      }

      CreateAddCommentForm() {
        this.addCommentForm = this.fb.group({
          CommentBody: [null]
        })
      }

      get CommentBody(){
        return this.addCommentForm.get('CommentBody') as FormControl;
      }

    //   commentData(): Comment {
    //     return this.comment = {
    //       postId: this.postId,
    //       comment: this.CommentBody.value,
    //       userName: localStorage.getItem('token'),
    //       commentedOn: new Date().toString()
    //     }
    //   }

    commentData(): any  {
        const comment: any  = {
          postId: this.postId, // Assuming this.postId is a property of your class or function
          comment: this.CommentBody.value,
          userName: localStorage.getItem('token') || '',
          commentedOn: new Date().toString(),
          // Add other properties from the Comment type as needed
        };

        return comment;
      }

      fetchComments() {
        this.addCommentService.getComments(this.postId).subscribe(
          comment_data=>{
            this.comments = comment_data.reverse();
            console.log(this.comments);
          }, error => {
            console.log('error');
          }
        );
      }

      fetchpost() {
        // this.postsService.getPost(this.postId).subscribe(
        //   (data: Blog) => {
        //     this.post = data;
        //     this.blogaBannerService.banner();
        //     this.newTitle = this.post.title;
        //     this.newDescription = this.post.description;
        //   }
        // );
      }

      loggedin() {
        this.loggedinUser = localStorage.getItem('token');
        this.commentBoxJS.commentBox();
        return this.loggedinUser;
      }

      isAdmin() {
        this.admin = localStorage.getItem('admin');
        return this.admin;
      }



      @Input() comment2 : any;


      newComment: string;
      commentId: number;

      updateComment(id: any) {
        this.commentId = Number(id);
        this.addCommentService.updateComment(this.commentId, this.newComment).subscribe(
          data => {
            this.isEdit = false;
            this.newComment = "";
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([this.router.url]);
          });

      }

      deleteComment(id:number) {
        this.addCommentService.deleteComment(id).subscribe(
          data => {
            this.alertify.success("Comment deleted");
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([this.router.url]);
          }
        )
      }



}
