import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QChoiceService } from '../../services/qchoice.service';
import { AlertifyService } from '../../services/alertify.service';
import { SystemService } from '../../services/system.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionsService } from '../../services/questions.service';
import { QuestionnaireTypeDetail } from '../../model/question';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { TypeService } from '../../services/type.services';
import { QuestionnairetypeService } from '../../services/questionnairetype.service';

@Component({
    selector: 'app-questiontype-form',
    templateUrl: './questiontype.html',
    styleUrls: ['./questiontype.css'],
})
export class QuestiontypeFormComponent implements OnInit {
    QuestiontypeForm: FormGroup;
    questiontypes: any;
    id: any; Questionnaires: any;
    QuestionnaireId: any; TypeId: any;
    questionnairestypedata: any; QuestionnarireName: any;
    EditQuestiontype: any; questionnariestype: any;
    questiontype: any; typelist: any;

    constructor(private fb: FormBuilder, private questionnairetype: QuestionnairetypeService, private route: ActivatedRoute, private questionnaireService: QuestionnaireService,
        private router: Router, private questionService: QuestionsService, private alertify: AlertifyService,
        public service: SystemService, private typeService: TypeService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.loadtype();
        this.bindQuestionnairesById();
        this.loadquestionnariestype();

    }

    bindQuestionnaires() {
        this.service.App.ShowLoader = true;
        this.questionnaireService.getAllQuestionnaires().then(data => {
            this.Questionnaires = data;
        });
        this.service.App.ShowLoader = false;
    }

    bindQuestionnairesById() {
        try {
            console.log(this.id);
            this.questionnaireService.getQuestionnairesById(Number(this.id)).then(data => {
                data = this.convertKeysToCamelCase(data);
                console.log(data);
                this.QuestionnaireId = data.Id;
                this.QuestionnarireName = data.Name;
                console.log(this.QuestionnaireId);
                console.log(this.QuestionnarireName);
            });
        } catch (error) {
            // Handle errors here
            console.error("Error submitting questionnaire:", error);
            this.alertify.error("An error occurred while saving data.");
        }
    }

    ngOnInit() {
        this.QuestiontypeForm = this.fb.group({
            ApplyId: 0,
            QuestionnaireId: [null, Validators.required],
            TypeId: [null, Validators.required],
            CreatedDate: [null, Validators.required],
            UserId: [null, Validators.required],
            Active: [false]
        });
    }

    loadtype() {
        this.service.App.ShowLoader = true;
        this.typeService.getAllTypelist().then(typelist => {
            this.typelist = typelist;
        });
        this.service.App.ShowLoader = false;

    }

    loadquestionnariestype() {
        this.service.App.ShowLoader = true;
        this.questionnairetype.getQuestionnairestypeQuestionById(this.id).then(data => {
            console.log(data);
            this.questionnariestype = data;
        });
        this.service.App.ShowLoader = false;

    }


    async onSubmit() {
        const questionnaireTypeDetail: QuestionnaireTypeDetail = this.QuestiontypeForm.value;
        let obj = this.QuestiontypeForm.getRawValue();
        console.log(this.EditQuestiontype);
        if (this.EditQuestiontype) {
            //Update
            questionnaireTypeDetail.ApplyId = this.EditQuestiontype.applyId;
            questionnaireTypeDetail.QuestionnaireId = this.EditQuestiontype.questionnaireId;
            // const typeIds = obj.TypeId.map(String).join(',');
            // questionnaireTypeDetail.TypeId = typeIds;
            console.log("Update: " + JSON.stringify(questionnaireTypeDetail));
            let res = this.questionnairetype.updateQuestionnairestype(questionnaireTypeDetail);
            if (res) {
                this.loadquestionnariestype();
                this.alertify.success("Data Saved Successfully");
            }
            else
            {
                this.alertify.error("Data Not Saved!!!");
            }

        } else {
            console.log("Add: " + JSON.stringify(this.questionnairestypedata));
            questionnaireTypeDetail.ApplyId = 0;
            questionnaireTypeDetail.QuestionnaireId = this.QuestionnaireId;
            questionnaireTypeDetail.UserId = localStorage.getItem("id");
            // const typeIds = obj.TypeId.map(String).join(',');
            // questionnaireTypeDetail.TypeId = typeIds;
            questionnaireTypeDetail.Active = true;
            console.log("Add: " + JSON.stringify(questionnaireTypeDetail));
            let res = this.questionnairetype.addQuestionnairestype(questionnaireTypeDetail);
            if (res) {
                console.log(JSON.stringify(res));
                this.loadquestionnariestype();
                this.alertify.success("Data Saved Successfully");
            }
            else
            {
                this.alertify.error("Data Not Saved!!!");
            }
        }
        // Reset the form
        this.QuestiontypeForm.reset();
        this.EditQuestiontype = null;
    }


    onEdit(data: any) {
        console.log(data);
        this.EditQuestiontype = data;
        this.TypeId = data.typeId;
    }

    onDelete(Id: number) {
        let res = this.questionnairetype.deleteQuestionnairestype(Id);
        if (res) {
            this.loadquestionnariestype(); // Refresh the category list
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
}




