
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { QuestionnaireTypeDetail } from '../model/question';




@Injectable({
    providedIn: 'root'
})
export class QuestionnairetypeService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    //Add QuestionnaireTypeDetails
    async addQuestionnairestype(data: QuestionnaireTypeDetail): Promise<Observable<QuestionnaireTypeDetail>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Questionnairestype", data);
        console.log(res);
        return res;
    }

    async updateQuestionnairestype(data: QuestionnaireTypeDetail): Promise<Observable<QuestionnaireTypeDetail>> {
        let res = await this.service.Data.ExecuteAPI_Put("Questionnairestype", `${data.ApplyId}?applyid=${data.ApplyId}`, data);
        return res;
    }

    //Get All QuestionnaireTypeDetails
    async getAllQuestionnairestype(): Promise<QuestionnaireTypeDetail[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<QuestionnaireTypeDetail[]>("Questionnairestype");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id QuestionnaireTypeDetails
    async getQuestionnairestypeById(id: number): Promise<QuestionnaireTypeDetail> {
        try {
          return await this.service.Data.ExecuteAPI_Get<QuestionnaireTypeDetail>("Questionnairestype/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async getQuestionnairestypeQuestionById(id: number): Promise<any[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<any[]>("Questionnairestype/questionId/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async deleteQuestionnairestype(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("Questionnairestype", `${id}?applyid=${id}`);
    }

}
