
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterHeaderModel } from '../../model/filterHeader';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-filterHeader_add-form',
    templateUrl: './filterHeader_add.html',
    styleUrls: ['./filterHeader_add.css'],
})
export class FilterHeaderAddformComponent implements OnInit {
    filterHeaderform: FormGroup;
    FilterHeader: any;
    selectedFilterHeaderModel: FilterHeaderModel | null = null;


    constructor(private fb: FormBuilder, private router: Router, private FilterHeaderModelService: FilterHeaderService, private alertify: AlertifyService,) {
        this.loadFilterHeader();
    }

    ngOnInit() {
        this.filterHeaderform = this.fb.group({
            FilterId: 0,
            filterHeaderName1: ['', Validators.required],
            filterHeaderDescp: ['', Validators.required],
        });
    }

    Back() {
        this.router.navigate['/filterHeaderlist']
    }

    loadFilterHeader() {
        this.FilterHeaderModelService.getAllFilterHeader().then(FilterHeader => {
            this.FilterHeader = FilterHeader;
            console.log(JSON.stringify(this.FilterHeader));
        });
    }

    async onSubmit() {
        if (this.filterHeaderform.valid) {
            let obj = this.filterHeaderform.getRawValue();
            console.log(JSON.stringify(obj));
            let res = this.FilterHeaderModelService.addFilterHeader(obj);
            if (res) {
                this.router.navigate(['/filterHeaderlist']);
                this.alertify.success("Data Saved Successfully");

            }
        }
        // Reset the form
        this.filterHeaderform.reset();
        this.selectedFilterHeaderModel = null;
    }

    onEdit(FilterHeaderModel: FilterHeaderModel) {
        // Load the selected FilterHeaderModel into the form for editing
        this.selectedFilterHeaderModel = FilterHeaderModel;
        this.filterHeaderform.patchValue(FilterHeaderModel);
    }

    onDelete(FilterId: number) {
        let res = this.FilterHeaderModelService.deleteFilterHeader(FilterId);
        if (res) {
            this.loadFilterHeader(); // Refresh the FilterHeaderModel list
        }
    }


}


export { FilterHeaderModel };

